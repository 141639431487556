// Logbook.js

import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import './Logbook.css';
import { useUserContext } from '../context/UserContext';
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  Timestamp,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  Modal,
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default icon issues in Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const Logbook = () => {
  const { clubData } = useUserContext();
  const auth = getAuth();
  const db = getFirestore();

  const [logEntries, setLogEntries] = useState([]);
  const [members, setMembers] = useState([]);
  const [planes, setPlanes] = useState([]);
  const [memberDocId, setMemberDocId] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [modalMode, setModalMode] = useState('create');
  const [selectedEntry, setSelectedEntry] = useState(null);

  // Delete confirmation dialog state
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // Form Fields
  const [date, setDate] = useState('');
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');
  const [offBlock, setOffBlock] = useState('');
  const [onBlock, setOnBlock] = useState('');
  const [aircraft, setAircraft] = useState('');
  const [pilot, setPilot] = useState('');
  const [flightType, setFlightType] = useState('');

  // Flight Time Fields (Hours and Minutes)
  const [totalFlightHours, setTotalFlightHours] = useState('');
  const [totalFlightMinutes, setTotalFlightMinutes] = useState('');
  const [picHours, setPicHours] = useState('');
  const [picMinutes, setPicMinutes] = useState('');
  const [pfHours, setPfHours] = useState('');
  const [pfMinutes, setPfMinutes] = useState('');
  const [multiPilotHours, setMultiPilotHours] = useState('');
  const [multiPilotMinutes, setMultiPilotMinutes] = useState('');
  const [soloHours, setSoloHours] = useState('');
  const [soloMinutes, setSoloMinutes] = useState('');
  const [coPilotHours, setCoPilotHours] = useState('');
  const [coPilotMinutes, setCoPilotMinutes] = useState('');
  const [crossCountryHours, setCrossCountryHours] = useState('');
  const [crossCountryMinutes, setCrossCountryMinutes] = useState('');
  const [ifrHours, setIfrHours] = useState('');
  const [ifrMinutes, setIfrMinutes] = useState('');
  const [nightHours, setNightHours] = useState('');
  const [nightMinutes, setNightMinutes] = useState('');

  const [takeoffsDay, setTakeoffsDay] = useState('');
  const [takeoffsNight, setTakeoffsNight] = useState('');
  const [landingsDay, setLandingsDay] = useState('');
  const [landingsNight, setLandingsNight] = useState('');
  const [approaches, setApproaches] = useState({
    ils: false,
    llz: false,
    vor: false,
    ndb: false,
    visual: false,
  });
  const [comments, setComments] = useState('');

  // Route Information State
  const [showRouteSection, setShowRouteSection] = useState(false);
  const [departureICAO, setDepartureICAO] = useState('');
  const [waypoints, setWaypoints] = useState('');
  const [destinationICAO, setDestinationICAO] = useState('');
  const [routeCoordinates, setRouteCoordinates] = useState([]);
  const [showMap, setShowMap] = useState(false);

  // Airport Info State
  const [airportInfo, setAirportInfo] = useState({}); // Store airport data keyed by ICAO code
  const [selectedAirportInfo, setSelectedAirportInfo] = useState(null);
  const [openAirportInfoDialog, setOpenAirportInfoDialog] = useState(false);

  // Helper function for labels with tooltip
  const LabelWithTooltip = ({ label, tooltip }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{label}</span>
      <Tooltip title={tooltip} placement="top">
        <IconButton size="small" style={{ padding: 0, marginLeft: 4 }}>
          <HelpOutlineIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
  

  // Fetch logbook entries
  useEffect(() => {
    const fetchLogEntries = async () => {
      if (clubData && auth.currentUser && memberDocId) {
        const logbookRef = collection(
          db,
          `clubs/${clubData.orgnr}/members/${memberDocId}/logbook`
        );
        const logbookSnapshot = await getDocs(logbookRef);
        const entries = logbookSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLogEntries(entries);
      }
    };

    fetchLogEntries();
  }, [clubData, auth.currentUser, db, memberDocId]);

  // Fetch planes in the club
  useEffect(() => {
    const fetchPlanes = async () => {
      if (clubData) {
        const planesRef = collection(db, `clubs/${clubData.orgnr}/planes`);
        const planesSnapshot = await getDocs(planesRef);
        const planesData = planesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPlanes(planesData);
      }
    };

    fetchPlanes();
  }, [clubData, db]);

  // Fetch members in the club
  useEffect(() => {
    const fetchMembers = async () => {
      if (clubData && auth.currentUser) {
        const membersRef = collection(db, `clubs/${clubData.orgnr}/members`);
        const membersSnapshot = await getDocs(membersRef);
        const membersData = membersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMembers(membersData);

        // Find member whose uid matches current user's uid
        const matchingMember = membersData.find(
          (member) => member.uid === auth.currentUser.uid
        );

        if (matchingMember) {
          setMemberDocId(matchingMember.id); // Store the document ID of the matching member
        }
      }
    };

    fetchMembers();
  }, [clubData, db, auth.currentUser]);

  // Initialize pilot to current user's uid
  useEffect(() => {
    if (auth.currentUser && !pilot) {
      setPilot(auth.currentUser.uid);
    }
  }, [auth.currentUser]);

  // Handle submitting a new or edited log entry
  const handleLogEntrySubmit = async () => {
    if (!clubData || !auth.currentUser || !memberDocId) return;

    // Get pilot's name
    let pilotName = '';

    if (pilot === auth.currentUser.uid || !pilot) {
      // Get current user's name from members
      const currentUserData = members.find(
        (member) => member.uid === auth.currentUser.uid
      );
      pilotName = currentUserData
        ? `${currentUserData.firstName} ${currentUserData.lastName}`
        : 'Ukjent Pilot';
    } else {
      const selectedPilot = members.find((member) => member.uid === pilot);
      pilotName = selectedPilot
        ? `${selectedPilot.firstName} ${selectedPilot.lastName}`
        : 'Ukjent Pilot';
    }

    const logbookRef = collection(
      db,
      `clubs/${clubData.orgnr}/members/${memberDocId}/logbook`
    );

    // Convert hours and minutes to total minutes for storage
    const convertToMinutes = (hours, minutes) => {
      const h = parseInt(hours) || 0;
      const m = parseInt(minutes) || 0;
      return h * 60 + m;
    };

    // Calculate total flight time based on fromTime and toTime if totalFlightTime is not provided
    let totalFlightTime = convertToMinutes(totalFlightHours, totalFlightMinutes);
    if ((!totalFlightHours && !totalFlightMinutes) && fromTime && toTime) {
      const startTime = new Date(`1970-01-01T${fromTime}:00`);
      const endTime = new Date(`1970-01-01T${toTime}:00`);
      let diff = (endTime - startTime) / 60000; // Difference in minutes
      if (diff < 0) diff += 24 * 60; // Adjust for times that cross midnight
      totalFlightTime = diff;
    }

    // Ensure routeCoordinates is an array of objects with necessary fields
    const processedRouteCoordinates = routeCoordinates.map((coord) => ({
      lat: coord.lat,
      lng: coord.lng,
      label: coord.label,
      icao: coord.icao,
      // Include only necessary info fields to avoid nested objects and large data
      info: {
        icao: coord.info.icao,
        iata: coord.info.iata,
        fullName: coord.info.fullName,
        shortName: coord.info.shortName,
        municipalityName: coord.info.municipalityName,
        country: coord.info.country,
        continent: coord.info.continent,
        elevation: coord.info.elevation,
        timeZone: coord.info.timeZone,
        location: coord.info.location,
      },
    }));

    const entryData = {
      date,
      fromTime,
      toTime,
      offBlock,
      onBlock,
      aircraft,
      pilot: pilotName, // Save pilot's name directly
      flightType,
      totalFlightTime: parseInt(totalFlightTime) || 0,
      picTime: convertToMinutes(picHours, picMinutes),
      pfTime: convertToMinutes(pfHours, pfMinutes),
      multiPilotTime: convertToMinutes(multiPilotHours, multiPilotMinutes),
      soloTime: convertToMinutes(soloHours, soloMinutes),
      coPilotTime: convertToMinutes(coPilotHours, coPilotMinutes),
      crossCountryTime: convertToMinutes(crossCountryHours, crossCountryMinutes),
      ifrTime: convertToMinutes(ifrHours, ifrMinutes),
      nightTime: convertToMinutes(nightHours, nightMinutes),
      takeoffsDay: parseInt(takeoffsDay) || 0,
      takeoffsNight: parseInt(takeoffsNight) || 0,
      landingsDay: parseInt(landingsDay) || 0,
      landingsNight: parseInt(landingsNight) || 0,
      approaches,
      comments,
      routeInfo: {
        departureICAO,
        waypoints,
        destinationICAO,
        routeCoordinates: processedRouteCoordinates, // Save as array of objects
      },
      lastEditedAt: Timestamp.now(),
    };

    try {
      if (modalMode === 'create') {
        entryData.createdAt = Timestamp.now();
        const docRef = await addDoc(logbookRef, entryData); // Add new entry to the logbook collection
        setLogEntries((prevEntries) => [
          ...prevEntries,
          { id: docRef.id, ...entryData },
        ]);
      } else if (modalMode === 'edit' && selectedEntry) {
        const entryDocRef = doc(
          db,
          `clubs/${clubData.orgnr}/members/${memberDocId}/logbook`,
          selectedEntry.id
        );
        await updateDoc(entryDocRef, entryData); // Update existing entry
        setLogEntries((prevEntries) =>
          prevEntries.map((entry) =>
            entry.id === selectedEntry.id ? { id: entry.id, ...entryData } : entry
          )
        );
      }
      setOpenModal(false);
      resetFormFields();
    } catch (error) {
      console.error('Error saving log entry:', error);
    }
  };

  // Handle deleting a log entry
  const handleDeleteEntry = async () => {
    if (!clubData || !auth.currentUser || !memberDocId || !selectedEntry) return;

    try {
      const entryDocRef = doc(
        db,
        `clubs/${clubData.orgnr}/members/${memberDocId}/logbook`,
        selectedEntry.id
      );
      await deleteDoc(entryDocRef);
      setLogEntries((prevEntries) =>
        prevEntries.filter((entry) => entry.id !== selectedEntry.id)
      );
      setOpenDeleteDialog(false);
      setOpenModal(false);
      setSelectedEntry(null);
    } catch (error) {
      console.error('Error deleting log entry:', error);
    }
  };

  const resetFormFields = () => {
    setDate('');
    setFromTime('');
    setToTime('');
    setOffBlock('');
    setOnBlock('');
    setAircraft('');
    setPilot(auth.currentUser ? auth.currentUser.uid : '');
    setFlightType('');
    setTotalFlightHours('');
    setTotalFlightMinutes('');
    setPicHours('');
    setPicMinutes('');
    setPfHours('');
    setPfMinutes('');
    setMultiPilotHours('');
    setMultiPilotMinutes('');
    setSoloHours('');
    setSoloMinutes('');
    setCoPilotHours('');
    setCoPilotMinutes('');
    setCrossCountryHours('');
    setCrossCountryMinutes('');
    setIfrHours('');
    setIfrMinutes('');
    setNightHours('');
    setNightMinutes('');
    setTakeoffsDay('');
    setTakeoffsNight('');
    setLandingsDay('');
    setLandingsNight('');
    setApproaches({
      ils: false,
      llz: false,
      vor: false,
      ndb: false,
      visual: false,
    });
    setComments('');
    setSelectedEntry(null);
    // Reset route info
    setDepartureICAO('');
    setWaypoints('');
    setDestinationICAO('');
    setShowRouteSection(false);
    setShowMap(false);
    setRouteCoordinates([]);
    setAirportInfo({});
  };

  // Handle clicking on a log entry to edit
  const handleEditEntryClick = (entry) => {
    setSelectedEntry(entry);
    setDate(entry.date || '');
    setFromTime(entry.fromTime || '');
    setToTime(entry.toTime || '');
    setOffBlock(entry.offBlock || '');
    setOnBlock(entry.onBlock || '');
    setAircraft(entry.aircraft || '');
    // Get pilot UID based on pilot name
    const pilotMember = members.find((member) => {
      const fullName = `${member.firstName} ${member.lastName}`;
      return fullName === entry.pilot;
    });
    setPilot(pilotMember ? pilotMember.uid : '');
    setFlightType(entry.flightType || '');

    // Convert total minutes back to hours and minutes
    const convertToHoursMinutes = (totalMinutes) => {
      const h = Math.floor(totalMinutes / 60);
      const m = totalMinutes % 60;
      return { hours: h.toString(), minutes: m.toString() };
    };

    const totalFlightTime = convertToHoursMinutes(entry.totalFlightTime || 0);
    setTotalFlightHours(totalFlightTime.hours);
    setTotalFlightMinutes(totalFlightTime.minutes);

    const picTime = convertToHoursMinutes(entry.picTime || 0);
    setPicHours(picTime.hours);
    setPicMinutes(picTime.minutes);

    const pfTime = convertToHoursMinutes(entry.pfTime || 0);
    setPfHours(pfTime.hours);
    setPfMinutes(pfTime.minutes);

    const multiPilotTime = convertToHoursMinutes(entry.multiPilotTime || 0);
    setMultiPilotHours(multiPilotTime.hours);
    setMultiPilotMinutes(multiPilotTime.minutes);

    const soloTime = convertToHoursMinutes(entry.soloTime || 0);
    setSoloHours(soloTime.hours);
    setSoloMinutes(soloTime.minutes);

    const coPilotTime = convertToHoursMinutes(entry.coPilotTime || 0);
    setCoPilotHours(coPilotTime.hours);
    setCoPilotMinutes(coPilotTime.minutes);

    const crossCountryTime = convertToHoursMinutes(entry.crossCountryTime || 0);
    setCrossCountryHours(crossCountryTime.hours);
    setCrossCountryMinutes(crossCountryTime.minutes);

    const ifrTime = convertToHoursMinutes(entry.ifrTime || 0);
    setIfrHours(ifrTime.hours);
    setIfrMinutes(ifrTime.minutes);

    const nightTime = convertToHoursMinutes(entry.nightTime || 0);
    setNightHours(nightTime.hours);
    setNightMinutes(nightTime.minutes);

    setTakeoffsDay(entry.takeoffsDay || '');
    setTakeoffsNight(entry.takeoffsNight || '');
    setLandingsDay(entry.landingsDay || '');
    setLandingsNight(entry.landingsNight || '');
    setApproaches(
      entry.approaches || {
        ils: false,
        llz: false,
        vor: false,
        ndb: false,
        visual: false,
      }
    );
    setComments(entry.comments || '');

    // Set route info
    if (entry.routeInfo) {
      setDepartureICAO(entry.routeInfo.departureICAO || '');
      setWaypoints(entry.routeInfo.waypoints || '');
      setDestinationICAO(entry.routeInfo.destinationICAO || '');
      setRouteCoordinates(entry.routeInfo.routeCoordinates || []);
      // Set airportInfo from routeCoordinates
      const airportInfoMap = {};
      entry.routeInfo.routeCoordinates.forEach((coord) => {
        airportInfoMap[coord.icao] = coord.info;
      });
      setAirportInfo(airportInfoMap);
      setShowRouteSection(true);
      setShowMap(
        entry.routeInfo.routeCoordinates &&
          entry.routeInfo.routeCoordinates.length > 0
      );
    } else {
      setDepartureICAO('');
      setWaypoints('');
      setDestinationICAO('');
      setRouteCoordinates([]);
      setShowRouteSection(false);
      setShowMap(false);
      setAirportInfo({});
    }
    setModalMode('edit');
    setOpenModal(true);
  };

  // Handle viewing a log entry
  const handleEntryClick = (entry) => {
    setSelectedEntry(entry);
    setModalMode('view');
    setOpenModal(true);
  };

  // Helper function to format minutes to HH:mm
  const formatMinutesToHoursMinutes = (totalMinutes) => {
    const h = Math.floor(totalMinutes / 60);
    const m = totalMinutes % 60;
    return `${h}t ${m}m`;
  };

  // DataGrid Columns
  const columns = [
    { field: 'date', headerName: 'Dato', width: 100 },
    { field: 'aircraft', headerName: 'Fly', width: 100 },
    {
      field: 'fromTime',
      headerName: 'Fra Tid',
      width: 90,
    },
    {
      field: 'toTime',
      headerName: 'Til Tid',
      width: 90,
    },
    {
      field: 'totalFlightTime',
      headerName: 'Totaltid',
      width: 100,
      valueGetter: (params) => {
        if (!params || !params.row) return '';
        return formatMinutesToHoursMinutes(params.row.totalFlightTime || 0);
      },
    },
    { field: 'pilot', headerName: 'Pilot', width: 150 },
    {
      field: 'flightType',
      headerName: 'Type Flyvning',
      width: 130,
    },
    {
      field: 'takeoffs',
      headerName: 'Takeoffs',
      width: 90,
      valueGetter: (params) => {
        if (!params || !params.row) return '';
        const day = parseInt(params.row.takeoffsDay) || 0;
        const night = parseInt(params.row.takeoffsNight) || 0;
        return day + night;
      },
    },
    {
      field: 'landings',
      headerName: 'Landinger',
      width: 90,
      valueGetter: (params) => {
        if (!params || !params.row) return '';
        const day = parseInt(params.row.landingsDay) || 0;
        const night = parseInt(params.row.landingsNight) || 0;
        return day + night;
      },
    },
  ];

  // Function to fetch coordinates based on ICAO codes and waypoints using backend API
  const generateRouteCoordinates = async () => {
    const icaoCodes = [departureICAO, ...waypoints.split(','), destinationICAO].map(
      (code) => code.trim().toUpperCase()
    );

    try {
      const response = await fetch('https://airportinfo.airtrakr.com/api/airports', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ icaoCodes }),
      });

      const data = await response.json();

      if (data.success) {
        // Now data.airportData is an array of { icao, data }
        const coordinates = data.airportData.map(({ icao, data }) => ({
          lat: data.location.lat,
          lng: data.location.lon,
          label: data.fullName || icao,
          icao,
          info: data, // Store the full data for the airport
        }));
        setRouteCoordinates(coordinates);

        // Create an object mapping ICAO code to airport info
        const airportInfoMap = {};
        data.airportData.forEach(({ icao, data }) => {
          airportInfoMap[icao] = data;
        });
        setAirportInfo(airportInfoMap);

        setShowMap(true);
      } else {
        alert(`Feil ved henting av data: ${data.message}`);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  // Handle showing airport info
  const handleShowAirportInfo = (icao) => {
    if (airportInfo[icao]) {
      setSelectedAirportInfo(airportInfo[icao]);
      setOpenAirportInfoDialog(true); // Open Dialog
    }
  };
  

  return (
    <div className="app-wrapper">
      <Sidebar />
      <main className="main-content">
        <section className="logbook-section">
          <h1>Min Loggbok</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalMode('create');
              resetFormFields();
              setOpenModal(true);
            }}
          >
            Legg til ny flygning
          </Button>
          <div className="logbook-table" style={{ marginTop: 20 }}>
            <DataGrid
              rows={logEntries}
              columns={columns}
              autoHeight
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50]}
              onRowClick={(params) => handleEntryClick(params.row)}
            />
          </div>
        </section>

        {/* Modal for Adding/Editing/Viewing Entries */}
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box className="logbook-modal">
            {modalMode === 'view' ? (
              selectedEntry && (
                <>
                  <Typography variant="h5" gutterBottom>
                    Loggføring Detaljer
                  </Typography>
                  <div className="details-group">
                    {/* Display entry details */}
                    <Paper elevation={3} className="details-paper">
                      <Typography variant="subtitle1" gutterBottom>
                        Grunnleggende Informasjon
                      </Typography>
                      <div className="details-grid">
                        <p>
                          <strong>Dato:</strong> {selectedEntry.date}
                        </p>
                        <p>
                          <strong>Fra Tid:</strong> {selectedEntry.fromTime}
                        </p>
                        <p>
                          <strong>Til Tid:</strong> {selectedEntry.toTime}
                        </p>
                        <p>
                          <strong>Avgangstid (Off Block):</strong>{' '}
                          {selectedEntry.offBlock}
                        </p>
                        <p>
                          <strong>Ankomsttid (On Block):</strong>{' '}
                          {selectedEntry.onBlock}
                        </p>
                        <p>
                          <strong>Sist Redigert:</strong>{' '}
                          {selectedEntry.lastEditedAt
                            ? selectedEntry.lastEditedAt.toDate().toLocaleString()
                            : 'Aldri'}
                        </p>
                      </div>
                    </Paper>

                    <Paper elevation={3} className="details-paper">
                      <Typography variant="subtitle1" gutterBottom>
                        Flyinformasjon
                      </Typography>
                      <div className="details-grid">
                        <p>
                          <strong>Fly:</strong> {selectedEntry.aircraft}
                        </p>
                        <p>
                          <strong>Pilot:</strong> {selectedEntry.pilot}
                        </p>
                        <p>
                          <strong>Type Flyvning:</strong> {selectedEntry.flightType}
                        </p>
                      </div>
                    </Paper>

                    <Paper elevation={3} className="details-paper">
                      <Typography variant="subtitle1" gutterBottom>
                        Flytid
                      </Typography>
                      <div className="details-grid">
                        <p>
                          <strong>Total Flytid:</strong>{' '}
                          {formatMinutesToHoursMinutes(
                            selectedEntry.totalFlightTime || 0
                          )}
                        </p>
                        <p>
                          <strong>PIC Tid:</strong>{' '}
                          {formatMinutesToHoursMinutes(selectedEntry.picTime || 0)}
                        </p>
                        <p>
                          <strong>PF Tid:</strong>{' '}
                          {formatMinutesToHoursMinutes(selectedEntry.pfTime || 0)}
                        </p>
                        <p>
                          <strong>Flerpilot Tid:</strong>{' '}
                          {formatMinutesToHoursMinutes(
                            selectedEntry.multiPilotTime || 0
                          )}
                        </p>
                        <p>
                          <strong>Solo Tid:</strong>{' '}
                          {formatMinutesToHoursMinutes(selectedEntry.soloTime || 0)}
                        </p>
                        <p>
                          <strong>Co-pilot Tid:</strong>{' '}
                          {formatMinutesToHoursMinutes(
                            selectedEntry.coPilotTime || 0
                          )}
                        </p>
                        <p>
                          <strong>Cross Country Tid:</strong>{' '}
                          {formatMinutesToHoursMinutes(
                            selectedEntry.crossCountryTime || 0
                          )}
                        </p>
                        <p>
                          <strong>IFR Tid:</strong>{' '}
                          {formatMinutesToHoursMinutes(selectedEntry.ifrTime || 0)}
                        </p>
                        <p>
                          <strong>Natt Flyvning Tid:</strong>{' '}
                          {formatMinutesToHoursMinutes(selectedEntry.nightTime || 0)}
                        </p>
                      </div>
                    </Paper>

                    <Paper elevation={3} className="details-paper">
                      <Typography variant="subtitle1" gutterBottom>
                        Landinger og Takeoffs
                      </Typography>
                      <div className="details-grid">
                        <p>
                          <strong>Takeoffs Dag:</strong> {selectedEntry.takeoffsDay}
                        </p>
                        <p>
                          <strong>Takeoffs Natt:</strong> {selectedEntry.takeoffsNight}
                        </p>
                        <p>
                          <strong>Landinger Dag:</strong> {selectedEntry.landingsDay}
                        </p>
                        <p>
                          <strong>Landinger Natt:</strong> {selectedEntry.landingsNight}
                        </p>
                      </div>
                    </Paper>

                    <Paper elevation={3} className="details-paper">
                      <Typography variant="subtitle1" gutterBottom>
                        IFR Innflygninger
                      </Typography>
                      <div className="details-grid">
                        <p>
                          <strong>ILS CAT I/II:</strong>{' '}
                          {selectedEntry.approaches.ils ? 'Ja' : 'Nei'}
                        </p>
                        <p>
                          <strong>LLZ:</strong>{' '}
                          {selectedEntry.approaches.llz ? 'Ja' : 'Nei'}
                        </p>
                        <p>
                          <strong>VOR:</strong>{' '}
                          {selectedEntry.approaches.vor ? 'Ja' : 'Nei'}
                        </p>
                        <p>
                          <strong>NDB:</strong>{' '}
                          {selectedEntry.approaches.ndb ? 'Ja' : 'Nei'}
                        </p>
                        <p>
                          <strong>Visuell:</strong>{' '}
                          {selectedEntry.approaches.visual ? 'Ja' : 'Nei'}
                        </p>
                      </div>
                    </Paper>

                    <Paper elevation={3} className="details-paper">
                      <Typography variant="subtitle1" gutterBottom>
                        Ruteinformasjon
                      </Typography>
                      <div className="details-grid">
                        <p>
                          <strong>Avgang ICAO:</strong>{' '}
                          {selectedEntry.routeInfo
                            ? selectedEntry.routeInfo.departureICAO
                            : ''}
                        </p>
                        <p>
                          <strong>Waypoints:</strong>{' '}
                          {selectedEntry.routeInfo
                            ? selectedEntry.routeInfo.waypoints
                            : ''}
                        </p>
                        <p>
                          <strong>Destinasjon ICAO:</strong>{' '}
                          {selectedEntry.routeInfo
                            ? selectedEntry.routeInfo.destinationICAO
                            : ''}
                        </p>
                      </div>

                      {/* Display Map if routeCoordinates are present */}
                      {selectedEntry.routeInfo &&
                        selectedEntry.routeInfo.routeCoordinates &&
                        selectedEntry.routeInfo.routeCoordinates.length > 0 && (
                          <>
                            <div className="map-container">
                              <MapContainer
                                center={[
                                  selectedEntry.routeInfo.routeCoordinates[0].lat,
                                  selectedEntry.routeInfo.routeCoordinates[0].lng,
                                ]}
                                zoom={6}
                                style={{ height: '400px', width: '100%', marginTop: 20 }}
                              >
                                <TileLayer
                                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                  attribution="&copy; OpenStreetMap contributors"
                                />
                                <Polyline
                                  positions={selectedEntry.routeInfo.routeCoordinates.map(
                                    (coord) => [coord.lat, coord.lng]
                                  )}
                                  color="blue"
                                />
                                {selectedEntry.routeInfo.routeCoordinates.map(
                                  (coord, index) => (
<Marker
  key={index}
  position={[coord.lat, coord.lng]}
  eventHandlers={{
    click: () => {
      handleShowAirportInfo(coord.icao);
    },
  }}
>
  <Popup>{coord.label}</Popup>
</Marker>
                                  )
                                )}
                              </MapContainer>
                            </div>

                          </>
                        )}
                    </Paper>

                    <Paper elevation={3} className="details-paper">
                      <Typography variant="subtitle1" gutterBottom>
                        Kommentarer
                      </Typography>
                      <div className="details-grid">
                        <p>{selectedEntry.comments}</p>
                      </div>
                    </Paper>
                  </div>
                  <div className="button-group">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEditEntryClick(selectedEntry)}
                      style={{ marginRight: 8 }}
                    >
                      Rediger
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpenDeleteDialog(true)}
                      style={{ marginRight: 8 }}
                    >
                      Slett
                    </Button>
                    <Button
                      onClick={() => setOpenModal(false)}
                      variant="outlined"
                      color="default"
                    >
                      Lukk
                    </Button>
                  </div>
                </>
              )
            ) : (
              <>
                <Typography variant="h5" gutterBottom>
                  {modalMode === 'create' ? 'Ny Loggføring' : 'Rediger Loggføring'}
                </Typography>

                {/* Form Sections */}
                {/* Grunnleggende Informasjon */}
                <div className="form-section">
                  <Typography variant="subtitle1" gutterBottom>
                    Grunnleggende Informasjon
                  </Typography>
                  <div className="form-grid">
                    <TextField
                      label={
                        <LabelWithTooltip label="Dato" tooltip="Datoen for flyturen" />
                      }
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      fullWidth
                    />
                    <TextField
                      label={
                        <LabelWithTooltip
                          label="Fra Tid"
                          tooltip="Tidspunktet du startet flyturen"
                        />
                      }
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      value={fromTime}
                      onChange={(e) => setFromTime(e.target.value)}
                      fullWidth
                    />
                    <TextField
                      label={
                        <LabelWithTooltip
                          label="Til Tid"
                          tooltip="Tidspunktet du avsluttet flyturen"
                        />
                      }
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      value={toTime}
                      onChange={(e) => setToTime(e.target.value)}
                      fullWidth
                    />
                    <TextField
                      label={
                        <LabelWithTooltip
                          label="Avgangstid (Off Block)"
                          tooltip="Tidspunktet flyet begynte å bevege seg for avgang"
                        />
                      }
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      value={offBlock}
                      onChange={(e) => setOffBlock(e.target.value)}
                      fullWidth
                    />
                    <TextField
                      label={
                        <LabelWithTooltip
                          label="Ankomsttid (On Block)"
                          tooltip="Tidspunktet flyet stanset etter landing"
                        />
                      }
                      type="time"
                      InputLabelProps={{ shrink: true }}
                      value={onBlock}
                      onChange={(e) => setOnBlock(e.target.value)}
                      fullWidth
                    />
                  </div>
                </div>

                {/* Flyinformasjon */}
                <div className="form-section">
                  <Typography variant="subtitle1" gutterBottom>
                    Flyinformasjon
                  </Typography>
                  <div className="form-grid">
                    <FormControl fullWidth>
                      <InputLabel>
                        <LabelWithTooltip label="Fly" tooltip="Flyet du benyttet" />
                      </InputLabel>
                      <Select
                        value={aircraft}
                        onChange={(e) => setAircraft(e.target.value)}
                      >
                        <MenuItem value="">
                          <em>Velg fly</em>
                        </MenuItem>
                        {planes.map((plane) => (
                          <MenuItem key={plane.id} value={plane.callsign}>
                            {plane.callsign} - {plane.model}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel>
                        <LabelWithTooltip
                          label="Pilot"
                          tooltip="Piloten som gjennomførte flyvningen"
                        />
                      </InputLabel>
                      <Select
                        value={pilot}
                        onChange={(e) => setPilot(e.target.value)}
                      >
                        {auth.currentUser && (
                          <MenuItem value={auth.currentUser.uid}>
                            {members.find(
                              (member) => member.uid === auth.currentUser.uid
                            )
                              ? `${members.find(
                                  (member) => member.uid === auth.currentUser.uid
                                ).firstName} ${
                                  members.find(
                                    (member) => member.uid === auth.currentUser.uid
                                  ).lastName
                                } (Deg)`
                              : 'Din bruker'}
                          </MenuItem>
                        )}
                        {members
                          .filter(
                            (member) =>
                              member.uid !== (auth.currentUser ? auth.currentUser.uid : '')
                          )
                          .map((member) => (
                            <MenuItem key={member.uid} value={member.uid}>
                              {member.firstName} {member.lastName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextField
                      label={
                        <LabelWithTooltip
                          label="Type Flyvning"
                          tooltip="F.eks. trening, passasjerflyvning, osv."
                        />
                      }
                      value={flightType}
                      onChange={(e) => setFlightType(e.target.value)}
                      fullWidth
                    />
                  </div>
                </div>

                {/* Flight Time Section */}
                <div className="form-section">
                  <Typography variant="subtitle1" gutterBottom>
                    Flytid
                  </Typography>
                  <div className="flight-time-grid">
                    {/* Column Headers */}
                    <div className="flight-time-row header">
                      <div></div>
                      <div>Timer</div>
                      <div>Minutter</div>
                    </div>
                    {/* Total Flight Time */}
                    <div className="flight-time-row">
                      <div>
                        <LabelWithTooltip
                          label="Total Flytid"
                          tooltip="Den totale tiden flyet var i luften"
                        />
                      </div>
                      <TextField
                        type="number"
                        value={totalFlightHours}
                        onChange={(e) => setTotalFlightHours(e.target.value)}
                        inputProps={{ min: 0 }}
                      />
                      <TextField
                        type="number"
                        value={totalFlightMinutes}
                        onChange={(e) => setTotalFlightMinutes(e.target.value)}
                        inputProps={{ min: 0, max: 59 }}
                      />
                    </div>
                    {/* PIC Time */}
                    <div className="flight-time-row">
                      <div>
                        <LabelWithTooltip
                          label="PIC Tid"
                          tooltip="Tid som Pilot In Command (ansvarlig pilot)"
                        />
                      </div>
                      <TextField
                        type="number"
                        value={picHours}
                        onChange={(e) => setPicHours(e.target.value)}
                        inputProps={{ min: 0 }}
                      />
                      <TextField
                        type="number"
                        value={picMinutes}
                        onChange={(e) => setPicMinutes(e.target.value)}
                        inputProps={{ min: 0, max: 59 }}
                      />
                    </div>
                    {/* PF Time */}
                    <div className="flight-time-row">
                      <div>
                        <LabelWithTooltip
                          label="PF Tid"
                          tooltip="Tid som Pilot Flying (den som styrer flyet)"
                        />
                      </div>
                      <TextField
                        type="number"
                        value={pfHours}
                        onChange={(e) => setPfHours(e.target.value)}
                        inputProps={{ min: 0 }}
                      />
                      <TextField
                        type="number"
                        value={pfMinutes}
                        onChange={(e) => setPfMinutes(e.target.value)}
                        inputProps={{ min: 0, max: 59 }}
                      />
                    </div>
                    {/* Multi-Pilot Time */}
                    <div className="flight-time-row">
                      <div>
                        <LabelWithTooltip
                          label="Flerpilot Tid"
                          tooltip="Tid med flere piloter ombord"
                        />
                      </div>
                      <TextField
                        type="number"
                        value={multiPilotHours}
                        onChange={(e) => setMultiPilotHours(e.target.value)}
                        inputProps={{ min: 0 }}
                      />
                      <TextField
                        type="number"
                        value={multiPilotMinutes}
                        onChange={(e) => setMultiPilotMinutes(e.target.value)}
                        inputProps={{ min: 0, max: 59 }}
                      />
                    </div>
                    {/* Solo Time */}
                    <div className="flight-time-row">
                      <div>
                        <LabelWithTooltip
                          label="Solo Tid"
                          tooltip="Tid du fløy alene"
                        />
                      </div>
                      <TextField
                        type="number"
                        value={soloHours}
                        onChange={(e) => setSoloHours(e.target.value)}
                        inputProps={{ min: 0 }}
                      />
                      <TextField
                        type="number"
                        value={soloMinutes}
                        onChange={(e) => setSoloMinutes(e.target.value)}
                        inputProps={{ min: 0, max: 59 }}
                      />
                    </div>
                    {/* Co-Pilot Time */}
                    <div className="flight-time-row">
                      <div>
                        <LabelWithTooltip
                          label="Co-pilot Tid"
                          tooltip="Tid som andrepilot"
                        />
                      </div>
                      <TextField
                        type="number"
                        value={coPilotHours}
                        onChange={(e) => setCoPilotHours(e.target.value)}
                        inputProps={{ min: 0 }}
                      />
                      <TextField
                        type="number"
                        value={coPilotMinutes}
                        onChange={(e) => setCoPilotMinutes(e.target.value)}
                        inputProps={{ min: 0, max: 59 }}
                      />
                    </div>
                    {/* Cross Country Time */}
                    <div className="flight-time-row">
                      <div>
                        <LabelWithTooltip
                          label="Cross Country Tid"
                          tooltip="Tid brukt på navigasjonsflyvninger"
                        />
                      </div>
                      <TextField
                        type="number"
                        value={crossCountryHours}
                        onChange={(e) => setCrossCountryHours(e.target.value)}
                        inputProps={{ min: 0 }}
                      />
                      <TextField
                        type="number"
                        value={crossCountryMinutes}
                        onChange={(e) => setCrossCountryMinutes(e.target.value)}
                        inputProps={{ min: 0, max: 59 }}
                      />
                    </div>
                    {/* IFR Time */}
                    <div className="flight-time-row">
                      <div>
                        <LabelWithTooltip
                          label="IFR Tid"
                          tooltip="Tid fløyet under Instrument Flight Rules (IFR)"
                        />
                      </div>
                      <TextField
                        type="number"
                        value={ifrHours}
                        onChange={(e) => setIfrHours(e.target.value)}
                        inputProps={{ min: 0 }}
                      />
                      <TextField
                        type="number"
                        value={ifrMinutes}
                        onChange={(e) => setIfrMinutes(e.target.value)}
                        inputProps={{ min: 0, max: 59 }}
                      />
                    </div>
                    {/* Night Time */}
                    <div className="flight-time-row">
                      <div>
                        <LabelWithTooltip
                          label="Natt Flyvning Tid"
                          tooltip="Tid fløyet under natteforhold"
                        />
                      </div>
                      <TextField
                        type="number"
                        value={nightHours}
                        onChange={(e) => setNightHours(e.target.value)}
                        inputProps={{ min: 0 }}
                      />
                      <TextField
                        type="number"
                        value={nightMinutes}
                        onChange={(e) => setNightMinutes(e.target.value)}
                        inputProps={{ min: 0, max: 59 }}
                      />
                    </div>
                  </div>
                </div>

                {/* Landings and Takeoffs */}
                <div className="form-section">
                  <Typography variant="subtitle1" gutterBottom>
                    Landinger og Takeoffs
                  </Typography>
                  <div className="form-grid">
                    <TextField
                      label={
                        <LabelWithTooltip
                          label="Takeoffs Dag"
                          tooltip="Antall avgang under dagslys"
                        />
                      }
                      type="number"
                      value={takeoffsDay}
                      onChange={(e) => setTakeoffsDay(e.target.value)}
                      inputProps={{ min: 0 }}
                      fullWidth
                    />
                    <TextField
                      label={
                        <LabelWithTooltip
                          label="Takeoffs Natt"
                          tooltip="Antall avgang under natteforhold"
                        />
                      }
                      type="number"
                      value={takeoffsNight}
                      onChange={(e) => setTakeoffsNight(e.target.value)}
                      inputProps={{ min: 0 }}
                      fullWidth
                    />
                    <TextField
                      label={
                        <LabelWithTooltip
                          label="Landinger Dag"
                          tooltip="Antall landinger under dagslys"
                        />
                      }
                      type="number"
                      value={landingsDay}
                      onChange={(e) => setLandingsDay(e.target.value)}
                      inputProps={{ min: 0 }}
                      fullWidth
                    />
                    <TextField
                      label={
                        <LabelWithTooltip
                          label="Landinger Natt"
                          tooltip="Antall landinger under natteforhold"
                        />
                      }
                      type="number"
                      value={landingsNight}
                      onChange={(e) => setLandingsNight(e.target.value)}
                      inputProps={{ min: 0 }}
                      fullWidth
                    />
                  </div>
                </div>

                {/* IFR Approaches Section */}
                <div className="form-section">
                  <Typography variant="subtitle1" gutterBottom>
                    IFR Innflygninger
                  </Typography>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={approaches.ils}
                          onChange={(e) =>
                            setApproaches({ ...approaches, ils: e.target.checked })
                          }
                        />
                      }
                      label={
                        <LabelWithTooltip
                          label="ILS CAT I/II"
                          tooltip="Instrument Landing System kategori I/II"
                        />
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={approaches.llz}
                          onChange={(e) =>
                            setApproaches({ ...approaches, llz: e.target.checked })
                          }
                        />
                      }
                      label={
                        <LabelWithTooltip
                          label="LLZ"
                          tooltip="Localizer Approach (kun horisontal veiledning)"
                        />
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={approaches.vor}
                          onChange={(e) =>
                            setApproaches({ ...approaches, vor: e.target.checked })
                          }
                        />
                      }
                      label={
                        <LabelWithTooltip
                          label="VOR"
                          tooltip="VHF Omnidirectional Range Approach"
                        />
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={approaches.ndb}
                          onChange={(e) =>
                            setApproaches({ ...approaches, ndb: e.target.checked })
                          }
                        />
                      }
                      label={
                        <LabelWithTooltip
                          label="NDB"
                          tooltip="Non-Directional Beacon Approach"
                        />
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={approaches.visual}
                          onChange={(e) =>
                            setApproaches({ ...approaches, visual: e.target.checked })
                          }
                        />
                      }
                      label={
                        <LabelWithTooltip
                          label="Visuell"
                          tooltip="Visuell innflygning uten instrumenter"
                        />
                      }
                    />
                  </FormGroup>
                </div>

                {/* Toggle Route Section */}
                <Button
                  variant="outlined"
                  onClick={() => setShowRouteSection(!showRouteSection)}
                  style={{ marginTop: 20 }}
                >
                  {showRouteSection
                    ? 'Skjul Ruteinformasjon'
                    : 'Legg til ekstra Ruteinformasjon'}
                </Button>

                {showRouteSection && (
                  <div className="route-section">
                    <Typography variant="subtitle1" gutterBottom>
                      Ruteinformasjon
                    </Typography>
                    <div className="form-grid">
                      <TextField
                        label={
                          <LabelWithTooltip
                            label="Avgang ICAO"
                            tooltip="ICAO-koden for avgangsflyplassen"
                          />
                        }
                        value={departureICAO}
                        onChange={(e) => setDepartureICAO(e.target.value)}
                        fullWidth
                      />
                      <TextField
                        label={
                          <LabelWithTooltip
                            label="Waypoints"
                            tooltip="Kommaseparert liste over waypoints"
                          />
                        }
                        value={waypoints}
                        onChange={(e) => setWaypoints(e.target.value)}
                        fullWidth
                        disabled
                      />
                      <TextField
                        label={
                          <LabelWithTooltip
                            label="Destinasjon ICAO"
                            tooltip="ICAO-koden for destinasjonsflyplassen"
                          />
                        }
                        value={destinationICAO}
                        onChange={(e) => setDestinationICAO(e.target.value)}
                        fullWidth
                      />
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={generateRouteCoordinates}
                      style={{ marginTop: 20 }}
                    >
                      Generer kartrute
                    </Button>

                    {showMap && routeCoordinates.length > 0 && (
                      <>
                        <div className="map-container">
                          <MapContainer
                            center={[routeCoordinates[0].lat, routeCoordinates[0].lng]}
                            zoom={6}
                            style={{ height: '400px', width: '100%', marginTop: 20 }}
                          >
                            <TileLayer
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              attribution="&copy; OpenStreetMap contributors"
                            />
                            <Polyline
                              positions={routeCoordinates.map((coord) => [
                                coord.lat,
                                coord.lng,
                              ])}
                              color="blue"
                            />
                            {routeCoordinates.map((coord, index) => (
                              <Marker
                                key={index}
                                position={[coord.lat, coord.lng]}
                                eventHandlers={{
                                  click: () => {
                                    handleShowAirportInfo(coord.icao);
                                  },
                                }}
                              >
                                <Popup>{coord.label}</Popup>
                              </Marker>
                            ))}
                          </MapContainer>
                        </div>

                      </>
                    )}
                  </div>
                )}

                {/* Comments Section */}
                <div className="form-section">
                  <Typography variant="subtitle1" gutterBottom>
                    Kommentarer
                  </Typography>
                  <TextField
                    label={
                      <LabelWithTooltip
                        label="Kommentar"
                        tooltip="Eventuelle tilleggsopplysninger"
                      />
                    }
                    multiline
                    rows={4}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    fullWidth
                  />
                </div>

                <div className="button-group">
                  <Button
                    onClick={handleLogEntrySubmit}
                    variant="contained"
                    color="primary"
                  >
                    {modalMode === 'create' ? 'Lagre' : 'Oppdater'}
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenModal(false);
                      resetFormFields();
                    }}
                    variant="outlined"
                    color="secondary"
                  >
                    Avbryt
                  </Button>
                </div>
              </>
            )}
          </Box>
        </Modal>

              {/* Airport Info Dialog */}
              <Dialog
          open={openAirportInfoDialog}
          onClose={() => setOpenAirportInfoDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          {selectedAirportInfo && (
            <>
              <DialogTitle>
                {selectedAirportInfo.fullName || selectedAirportInfo.icao}
              </DialogTitle>
              <DialogContent dividers>
                <div className="airport-details">
                  <p>
                    <strong>ICAO:</strong> {selectedAirportInfo.icao}
                  </p>
                  <p>
                    <strong>IATA:</strong> {selectedAirportInfo.iata}
                  </p>
                  <p>
                    <strong>Fullt Navn:</strong> {selectedAirportInfo.fullName}
                  </p>
                  <p>
                    <strong>Kort Navn:</strong> {selectedAirportInfo.shortName}
                  </p>
                  <p>
                    <strong>By:</strong> {selectedAirportInfo.municipalityName}
                  </p>
                  <p>
                    <strong>Land:</strong> {selectedAirportInfo.country.name}
                  </p>
                  <p>
                    <strong>Kontinent:</strong> {selectedAirportInfo.continent.name}
                  </p>
                  <p>
                    <strong>Høyde (fot):</strong> {selectedAirportInfo.elevation.feet}
                  </p>
                  <p>
                    <strong>Tidssone:</strong> {selectedAirportInfo.timeZone}
                  </p>
                  <p>
                    <strong>Breddegrad:</strong> {selectedAirportInfo.location.lat}
                  </p>
                  <p>
                    <strong>Lengdegrad:</strong> {selectedAirportInfo.location.lon}
                  </p>
                  {/* Add more fields as needed */}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenAirportInfoDialog(false)}
                  variant="outlined"
                  color="primary"
                >
                  Lukk
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>Slett Loggføring</DialogTitle>
          <DialogContent>
            <Typography>
              Er du sikker på at du vil slette denne loggføringen? Denne handlingen kan
              ikke angres.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDeleteDialog(false)}
              variant="outlined"
              color="primary"
            >
              Avbryt
            </Button>
            <Button
              onClick={handleDeleteEntry}
              variant="contained"
              color="secondary"
            >
              Slett
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    </div>
  );
};

export default Logbook;
