// src/components/Login.js

import React, { useEffect, useState } from 'react';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Login.css';  // Import the CSS
import logo from '../assets/logo_main.png';
import halsenLogo from '../assets/logo_main.png';  // Import the semi-transparent logo

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const auth = getAuth();

  // If the user is already logged in, redirect to the dashboard
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/dashboard');
      }
    });
    return () => unsubscribe();
  }, [auth, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (error) {
      setErrorMessage('Feil brukernavn eller passord.');
    }
  };

  return (
    <div className="login-container">
      <meta name="theme-color" content="#ffffff" />
      <div className="login-logo-container">
        <img src={logo} alt="AirTrakr" className="login-logo" />
      </div>
      <div className="login-card">
        <h2 className="login-heading">Logg inn til AirTrakr</h2>
        <form onSubmit={handleLogin}>
          <div className="login-form-group">
            <label htmlFor="email" className="login-label">E-post</label>
            <input
              type="email"
              id="email"
              className="login-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Skriv inn e-post"
              required
            />
          </div>

          <div className="login-form-group">
            <label htmlFor="password" className="login-label">Passord</label>
            <input
              type="password"
              id="password"
              className="login-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Skriv inn passord"
              required
            />
          </div>

          {errorMessage && <p className="login-error">{errorMessage}</p>}

          <button type="submit" className="login-button">Logg inn</button>
        </form>


      </div>
              {/* Small gray text */}
              <p className="login-footer-text">Av flyvere - for flyvere</p>
        
        {/* Semi-transparent logo */}
        <div className="halsen-logo-container">
          <img src={halsenLogo} alt="Halsen Logo" className="halsen-logo" />
        </div>
    </div>
  );
};

export default Login;
