// src/components/SelectClub.js

import React from 'react';
import './SelectClub.css';  // Import the CSS
import { useUserContext } from '../context/UserContext';
import AirTrakrLogo from '../assets/logo_main.png';  // Import the AirTrakr logo
import { getAuth, signOut } from 'firebase/auth';  // Import Firebase Auth
import { useNavigate } from 'react-router-dom';

const SelectClub = () => {
  const { userClubs, handleSelectClub, setUserData, setClubData, setClubSelected } = useUserContext();
  const navigate = useNavigate();
  const auth = getAuth();  // Initialize Firebase Auth

  // Handle Logout
  const handleLogout = async () => {
    try {
      await signOut(auth);  // Sign out the user from Firebase Auth
      setUserData(null);  // Clear the user data from context
      setClubData(null);  // Clear the club data from context
      setClubSelected(false);  // Reset the club selection state
      navigate('/login');  // Redirect to login page after successful logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div className="select-club-page">
      <meta name="theme-color" content="#ffffff" />
      {/* Logo at the top */}
      <div className="logo-container">
        <img src={AirTrakrLogo} alt="AirTrakr Logo" className="airtrakr-logo" />
      </div>

      <div className="select-heading">Velg din flyklubb</div>
      <div className="divider">

        
        <br />
      </div>


      <div className="club-cards-container">
        {userClubs.map((club) => (
          <div key={club.clubId} className="club-card">
            <div className="club-name">{club.navn}</div>
            <p>Adresse: {club.adresse}</p>
            <p>Postnummer: {club.postnummer}, {club.poststed}</p>
            <button className="select-button" onClick={() => handleSelectClub(club.clubId)}>
              Velg Klubben
            </button>
          </div>
        ))}
      </div>

      <div className="divider">
        <span>eller</span>
        <br />
      </div>

      {/* Logout button */}
      <button className="logout-button-select-club" onClick={handleLogout}>
        Logg ut
      </button>
    </div>
  );
};

export default SelectClub;
