// src/components/Dashboard.js

import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Spinner from './Spinner';
import './Dashboard.css';
import { FaPlane, FaClock, FaCalendarAlt } from 'react-icons/fa';
import { useUserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  doc,
  updateDoc,
  where,
  arrayUnion,
} from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';

// Import Firebase messaging functions
import { messaging } from '../firebase/firebase';
import { getToken, onMessage } from 'firebase/messaging';

const Dashboard = () => {
  const { userData, clubData, planes, loading } = useUserContext();
  const navigate = useNavigate();

  // State variables for fetched data
  const [bookings, setBookings] = useState([]);
  const [userBookingsCount, setUserBookingsCount] = useState(0);
  const [mostBookedPlane, setMostBookedPlane] = useState(null);
  const [currentMonthBookingsCount, setCurrentMonthBookingsCount] = useState(0);
  const [averageFlightTime, setAverageFlightTime] = useState(0);
  const [latestPost, setLatestPost] = useState(null); // State for latest post

  // Fetch bookings and compute statistics
  useEffect(() => {
    const fetchBookings = async () => {
      // Ensure all necessary data is available
      if (!clubData || !planes || planes.length === 0 || !userData) {
        return;
      }

      try {
        const db = getFirestore();
        let allBookings = [];

        for (const plane of planes) {
          const bookingsRef = collection(
            db,
            `clubs/${clubData.orgnr}/planes/${plane.id}/bookings`
          );
          const bookingsSnapshot = await getDocs(bookingsRef);
          const planeBookings = bookingsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            planeId: plane.id,
          }));
          allBookings = allBookings.concat(planeBookings);
        }

        setBookings(allBookings);

        // Process bookings to compute statistics
        const now = new Date();

        // **Kommende bookinger**: User's upcoming bookings
        const userBookings = allBookings.filter(
          (booking) =>
            booking.firstName === userData.firstName &&
            booking.lastName === userData.lastName &&
            new Date(booking.start) > now
        );
        setUserBookingsCount(userBookings.length);

        // **Mest bookede fly**: Most booked plane
        const planeBookingCounts = {};
        allBookings.forEach((booking) => {
          planeBookingCounts[booking.planeId] =
            (planeBookingCounts[booking.planeId] || 0) + 1;
        });
        const mostBookedPlaneId = Object.keys(planeBookingCounts).reduce((a, b) =>
          planeBookingCounts[a] > planeBookingCounts[b] ? a : b
        );
        const mostBookedPlaneData = planes.find(
          (plane) => plane.id === mostBookedPlaneId
        );
        setMostBookedPlane(mostBookedPlaneData);

        // **Siste aktivitet**: Bookings in the current month
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
        const bookingsThisMonth = allBookings.filter((booking) => {
          const startDate = new Date(booking.start);
          return (
            startDate.getMonth() === currentMonth &&
            startDate.getFullYear() === currentYear
          );
        });
        setCurrentMonthBookingsCount(bookingsThisMonth.length);

        // **Gjennomsnittlig flytid**: Average flight duration
        let totalMinutes = 0;
        let bookingCountWithDuration = 0;
        allBookings.forEach((booking) => {
          const durationStr = booking.expectedFlightDuration; // e.g., "3:15 minutter"
          const match = durationStr ? durationStr.match(/(\d+):(\d+)/) : null;
          if (match) {
            const hours = parseInt(match[1], 10);
            const minutes = parseInt(match[2], 10);
            const totalBookingMinutes = hours * 60 + minutes;
            totalMinutes += totalBookingMinutes;
            bookingCountWithDuration += 1;
          }
        });
        const averageMinutes = totalMinutes / bookingCountWithDuration || 0;
        setAverageFlightTime(averageMinutes);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };

    fetchBookings();
  }, [clubData, planes, userData]);

  // Fetch the latest post
  useEffect(() => {
    const fetchLatestPost = async () => {
      if (!clubData) return;

      try {
        const db = getFirestore();
        const postsRef = collection(db, `clubs/${clubData.orgnr}/posts`);
        const q = query(postsRef, orderBy('createdAt', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setLatestPost({ id: doc.id, ...doc.data() });
        } else {
          setLatestPost(null);
        }
      } catch (error) {
        console.error('Error fetching latest post:', error);
      }
    };

    fetchLatestPost();
  }, [clubData]);

  // Helper function to format minutes into hours and minutes
  const formatMinutesToHoursMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = Math.round(minutes % 60);
    return `${hours}t ${mins}m`;
  };

  // Request notification permission and get FCM token
  useEffect(() => {
    const requestNotificationPermission = async () => {
      if (!userData || !clubData) return;

      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          await getFcmTokenAndSave();
        } else {
          console.log('Notification permission denied.');
        }
      } catch (error) {
        console.error('Error requesting notification permission:', error);
      }
    };

    const getFcmTokenAndSave = async () => {
      try {
        const currentToken = await getToken(messaging, {
          vapidKey: 'BJUS3CWmAVoRvrX48aQtP40hStmkVqWaL3XuX_JhH77Das0DGX_bP_VUISeW7hs9RByl46fpcj0zanhAVsASrrM', // Replace with your VAPID public key
        });
        if (currentToken) {
          console.log('FCM token:', currentToken);

          // Find the member document in the club's members collection
          const db = getFirestore();
          const membersRef = collection(db, `clubs/${clubData.orgnr}/members`);
          const q = query(membersRef, where('uid', '==', userData.uid));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const memberDoc = querySnapshot.docs[0];
            const memberRef = doc(db, `clubs/${clubData.orgnr}/members`, memberDoc.id);

            // Save the FCM token to the member's document (e.g., in an array field 'fcmTokens')
            await updateDoc(memberRef, {
              fcmTokens: arrayUnion(currentToken),
            });
          } else {
            console.error('Member not found in club members collection.');
          }
        } else {
          console.log('No registration token available.');
        }
      } catch (error) {
        console.error('An error occurred while retrieving token.', error);
      }
    };

    requestNotificationPermission();
  }, [userData, clubData]);

  // Handle incoming messages while the app is in the foreground
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // Display notification
      if (Notification.permission === 'granted') {
        new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: '/path/to/your/icon.png', // Replace with the path to your app icon
        });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (loading) {
    return <Spinner />; // Show the spinner while loading
  }

  if (!userData || !clubData) {
    console.log('User data or Club data is missing:', userData, clubData);
    return <Spinner />; // Use the spinner for the error state as well
  }

  const userName = `${userData.firstName} ${userData.lastName}`;

  return (
    <div className="app-wrapper">
      <Sidebar />
      <main className="main-content">
        <section className="welcome-section">
          <div className="title-container">
            <h1 className="dashboard-title">Velkommen tilbake, {userName}!</h1>
            <p className="dashboard-subtitle">
              Du er logget inn i <strong>{clubData.navn}</strong>
            </p>
          </div>
        </section>

        <div className="cards-container">
          <div className="card">
            <FaClock className="card-icon" />
            <h3 className="card-title">Totalt flytimer</h3>
            <p className="card-subtitle">0 timer</p>
          </div>

          <div className="card">
            <FaCalendarAlt className="card-icon" />
            <h3 className="card-title">Kommende bookinger</h3>
            <p className="card-subtitle">{userBookingsCount} planlagte flyvninger</p>
          </div>

          <div className="card">
            <FaPlane className="card-icon" />
            <h3 className="card-title">Tilgjengelige fly</h3>
            <p className="card-subtitle">{planes.length} fly tilgjengelig</p>
          </div>
        </div>

        {/* Latest Club News Section */}
        <section className="news-section">
          <h2 className="section-title">Siste klubbnyheter</h2>
          <div className="news-card">
            {latestPost ? (
              <div className="news-post">
                <div className="news-post-header">
                  <h3 className="news-post-title">{latestPost.title}</h3>
                  <p className="news-post-meta">
                    Publisert{' '}
                    {latestPost.createdAt?.toDate().toLocaleDateString('no-NO')}
                  </p>
                </div>
                <div className="news-post-body">
                  <ReactMarkdown>{latestPost.content}</ReactMarkdown>
                </div>
              </div>
            ) : (
              <p>Ingen nyheter tilgjengelig.</p>
            )}
          </div>
        </section>

        <section className="statistics-section">
          <h2 className="statistics-title">Klubbstatistikk</h2>
          <div className="statistics-container">
            <div className="stat-item">
              <h4>Mest bookede fly</h4>
              <p>
                {mostBookedPlane
                  ? `${mostBookedPlane.model} (${mostBookedPlane.callsign})`
                  : 'Ingen data'}
              </p>
            </div>
            <div className="stat-item">
              <h4>Siste aktivitet</h4>
              <p>{currentMonthBookingsCount} flyvninger denne måneden</p>
            </div>
            <div className="stat-item">
              <h4>Gjennomsnittlig flytid</h4>
              <p>
                {averageFlightTime
                  ? `${formatMinutesToHoursMinutes(averageFlightTime)} per flyvning`
                  : 'Ingen data'}
              </p>
            </div>
          </div>
        </section>
        <br />
        <div className="footer">
          <p>
            AirTrakr 2.5.0 Beta
            <br />
            Et verktøy utviklet av Halsen Group © 2024
          </p>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
