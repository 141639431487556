// src/index.js
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import * as serviceWorkerRegistration from './serviceWorkerRegistration.js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppWrapper from './AppWrapper';

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />  {/* Use AppWrapper as the root component */}
  </React.StrictMode>,
  document.getElementById('root')
);

// Register the service worker
serviceWorkerRegistration.register();