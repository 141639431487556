import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRouter';
import { UserProvider } from './context/UserContext'; // Import UserProvider

const AppWrapper = () => {
  return (
    <Router>
      <UserProvider> {/* Wrap everything in UserProvider inside Router */}
        
        <AppRouter />
      </UserProvider>
    </Router>
  );
};

export default AppWrapper;
