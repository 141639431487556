import React, { useState } from 'react';
import './Signup.css';  // Import the CSS for styling
import { db, auth } from '../firebase/firebase';  // Import Firestore and Auth from the central firebase.js file
import { doc, setDoc, collection, addDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import AirTrakrLogo from '../assets/logo_main.png';  // Import the AirTrakr logo
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const navigate = useNavigate();
  
  // State variables for organization details
  const [orgnr, setOrgnr] = useState('');
  const [clubData, setClubData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState(1);
  const [isClubVerified, setIsClubVerified] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // State variables for admin details
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [age, setAge] = useState('');

  // Fetch club data from BRREG
  const fetchClubData = async () => {
    try {
      const response = await fetch(`https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`);
      const data = await response.json();
      if (data.organisasjonsform.kode !== 'FLI') {
        setErrorMessage('Organisasjonsformen er ikke FLI. Kun foreninger er tillatt.');
        setClubData(null);
      } else {
        setClubData(data);
        setErrorMessage('');  // Clear error message
      }
    } catch (error) {
      console.error('Error fetching club data:', error);
      setErrorMessage('Kunne ikke hente klubbinformasjon.');
    }
  };

  // Handle Next Step for Club Information Confirmation
  const handleNextStep = () => {
    if (!clubData) {
      setErrorMessage('Bekreft at klubbinformasjonen er riktig.');
      return;
    }
    setIsClubVerified(true);
    setStep(2);  // Move to admin information step
  };

  // Firebase error messages
  const getFirebaseErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'E-postadressen er allerede i bruk.';
      case 'auth/invalid-email':
        return 'E-postadressen er ikke gyldig.';
      case 'auth/weak-password':
        return 'Passordet er for svakt. Passordet må være minst 6 tegn langt.';
      case 'auth/operation-not-allowed':
        return 'Oppretting av nye brukere er midlertidig deaktivert.';
      default:
        return 'En ukjent feil oppsto. Vennligst prøv igjen.';
    }
  };

  // Handle Signup Submission
  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Create club in Firestore
      const clubRef = doc(db, 'clubs', orgnr);
      await setDoc(clubRef, {
        orgnr: clubData.organisasjonsnummer,
        navn: clubData.navn,
        adresse: clubData.forretningsadresse.adresse.join(', '),
        postnummer: clubData.forretningsadresse.postnummer,
        poststed: clubData.forretningsadresse.poststed,
        stiftelsesdato: clubData.stiftelsesdato,
        admin: {
          firstName,
          lastName,
          email,
          age,
          uid: user.uid,
        }
      });

      // Add the admin as the first member in the club
      const membersRef = collection(clubRef, 'members');
      await addDoc(membersRef, {
        firstName,
        lastName,
        email,
        age,
        role: 'admin',
        uid: user.uid,
      });

      setSuccessMessage('Registrering vellykket, videresender til innlogging...');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      const errorMessage = getFirebaseErrorMessage(error.code);  // Get human-readable Firebase error
      console.error('Error signing up:', error);
      setErrorMessage(errorMessage);
    }
  };

  return (
    <div className="signup-page">
      <meta name="theme-color" content="#ffffff" />
      {/* Logo at the top outside the main card */}
      <div className="logo-container">
        <img src={AirTrakrLogo} alt="AirTrakr Logo" className="airtrakr-logo" />
      </div>

      <div className="signup-container">
        <form className="signup-form" onSubmit={handleSignup}>
          <h2>Registrer din flyklubb</h2>

          {/* Club Information Section */}
          {step === 1 && (
            <>
              <div className="club-info">
                <h3>Flyklubbinformasjon</h3>
                <div className="form-row">
                  <label className="custom-label">Organisasjonsnummer:</label>
                  <input
                    type="text"
                    value={orgnr}
                    onChange={(e) => setOrgnr(e.target.value)}
                    placeholder="Skriv inn organisasjonsnummer"
                    required
                  />
                </div>
                <button className='submit-button' type="button" onClick={fetchClubData}>
                  Finn Klubb
                </button>
                

                {clubData && (
                  <>
                    <div className="form-row">
                      <label className="custom-label">Klubbnavn</label>
                      <input type="text" value={clubData.navn} disabled />
                    </div>
                    <div className="form-row">
                      <label className="custom-label">Adresse</label>
                      <input type="text" value={clubData.forretningsadresse.adresse.join(', ')} disabled />
                    </div>
                    <div className="form-row">
                      <label className="custom-label">Postnummer</label>
                      <input type="text" value={clubData.forretningsadresse.postnummer} disabled />
                    </div>
                    <div className="form-row">
                      <label className="custom-label">Poststed</label>
                      <input type="text" value={clubData.forretningsadresse.poststed} disabled />
                    </div>
                    <div className="form-row">
                      <label className="custom-label">Stiftelsesdato</label>
                      <input type="text" value={clubData.stiftelsesdato} disabled />
                    </div>

                    <label>
                      <input
                        type="checkbox"
                        checked={isClubVerified}
                        onChange={() => setIsClubVerified(!isClubVerified)}
                      />{' '}
                      Bekreft at informasjonen er riktig
                    </label>
                    
                    <button className='submit-button' type="button" disabled={!isClubVerified} onClick={handleNextStep}>
                      Neste
                    </button>
                    <p className='sub-text'>Hvis informasjonen ikke stemmer, må dette endres hos <a href='https://www.brreg.no/lag-og-foreninger/endre-opplysninger-for-lag-eller-forening/'>Brønnøysundregistrene</a>.</p>
                  </>
                )}
              </div>
            </>
          )}

          {/* Admin Information Section */}
          {step === 2 && (
            <div className="admin-info">
              <h3>Administratorinformasjon</h3>
              <div className="form-row">
                <label className="custom-label">Fornavn</label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="form-row">
                <label className="custom-label">Etternavn</label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="form-row">
                <label className="custom-label">E-post</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-row">
                <label className="custom-label">Passord</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-row">
                <label className="custom-label">Alder</label>
                <input
                  type="number"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="submit-button">
                Registrer Klubben
              </button>
            </div>
          )}

          {errorMessage && <p className="error">{errorMessage}</p>}
          {successMessage && <p className="success">{successMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default Signup;
