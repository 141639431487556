// src/components/Inbox.js

import React, { useState, useEffect } from 'react';
import './Inbox.css';
import Sidebar from './Sidebar';
import { useUserContext } from '../context/UserContext';
import {
  getFirestore,
  collection,
  doc,
  updateDoc,
  arrayUnion,
  onSnapshot,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import * as Showdown from 'showdown';

const Inbox = () => {
  const { userData, clubData } = useUserContext();
  const [allPosts, setAllPosts] = useState([]);
  const [readPostIds, setReadPostIds] = useState([]);
  const [expandedPostIds, setExpandedPostIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [memberDocId, setMemberDocId] = useState(null); // State to store member document ID
  const db = getFirestore();
  const navigate = useNavigate();

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
  });

  useEffect(() => {
    // Check if userData and clubData are available
    if (!userData || !clubData) {
      // If not, return early
      return;
    }

    const fetchMemberDocId = async () => {
      try {
        // Query the members collection to find the document with uid equal to userData.uid
        const membersRef = collection(db, `clubs/${clubData.orgnr}/members`);
        const q = query(membersRef, where('uid', '==', userData.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const memberDoc = querySnapshot.docs[0];
          setMemberDocId(memberDoc.id);

          // Fetch read posts
          const userData = memberDoc.data();
          const userReadPosts = userData?.readPosts || [];
          setReadPostIds(userReadPosts);

          // Listen for updates to the member document
          const userDocRef = doc(db, `clubs/${clubData.orgnr}/members`, memberDoc.id);
          onSnapshot(userDocRef, (docSnapshot) => {
            const updatedUserData = docSnapshot.data();
            const updatedReadPosts = updatedUserData?.readPosts || [];
            setReadPostIds(updatedReadPosts);
          });
        } else {
          console.error('Member document not found');
        }
      } catch (error) {
        console.error('Error fetching member document:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMemberDocId();

    const postsRef = collection(db, `clubs/${clubData.orgnr}/posts`);

    // Fetch all posts
    const unsubscribePosts = onSnapshot(postsRef, (snapshot) => {
      const posts = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      // Sort posts by createdAt descending
      posts.sort((a, b) => {
        const dateA = a.createdAt?.toDate
          ? a.createdAt.toDate()
          : a.createdAt
          ? new Date(a.createdAt.seconds * 1000)
          : new Date();
        const dateB = b.createdAt?.toDate
          ? b.createdAt.toDate()
          : b.createdAt
          ? new Date(b.createdAt.seconds * 1000)
          : new Date();
        return dateB - dateA;
      });
      setAllPosts(posts);
    });

    return () => {
      unsubscribePosts();
    };
  }, [db, clubData, userData]);

  const handlePostClick = async (postId) => {
    if (!memberDocId || !clubData) return;

    const userDocRef = doc(db, `clubs/${clubData.orgnr}/members`, memberDocId);

    if (!readPostIds.includes(postId)) {
      try {
        await updateDoc(userDocRef, {
          readPosts: arrayUnion(postId),
        });
        setReadPostIds((prev) => [...prev, postId]);
      } catch (error) {
        console.error('Error updating read posts:', error);
      }
    }

    // Toggle expanded state
    setExpandedPostIds((prev) =>
      prev.includes(postId) ? prev.filter((id) => id !== postId) : [...prev, postId]
    );
  };

  const handleReadFullPost = () => {
    navigate('/myclub');
  };

  // If userData or clubData is not available yet, show a loading indicator
  if (!userData || !clubData) {
    return (
      <div className="inbox-app-wrapper">
        <Sidebar />
        <main className="inbox-main-content">
          <section className="inbox-section">
            <h1 className="inbox-title">Mine innboksvarsler</h1>
            <div className="inbox-posts-container">
              {[1, 2, 3].map((item) => (
                <div className="inbox-post-skeleton" key={item}>
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={20} width="60%" />
                  <Skeleton variant="rectangular" height={100} />
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    );
  }

  return (
    <div className="inbox-app-wrapper">
      <Sidebar />
      <main className="inbox-main-content">
        <section className="inbox-section">
          <h1 className="inbox-title">Mine innboksvarsler</h1>
          <div className="inbox-posts-container">
            {loading ? (
              // Skeleton loader while data is loading
              [1, 2, 3].map((item) => (
                <div className="inbox-post-skeleton" key={item}>
                  <Skeleton variant="text" height={40} />
                  <Skeleton variant="text" height={20} width="60%" />
                  <Skeleton variant="rectangular" height={100} />
                </div>
              ))
            ) : allPosts.length === 0 ? (
              <p className="inbox-no-posts-message">Ingen varsler å vise.</p>
            ) : (
              allPosts.map((post) => {
                const isUnread = !readPostIds.includes(post.id);
                const isExpanded = expandedPostIds.includes(post.id);
                const postContent = converter.makeHtml(post.content || '');

                return (
                  <div
                    key={post.id}
                    className={`inbox-post-card ${
                      isUnread ? 'inbox-post-unread' : 'inbox-post-read'
                    }`}
                  >
                    <div
                      className="inbox-post-header"
                      onClick={() => handlePostClick(post.id)}
                    >
                      <h2 className="inbox-post-title">{post.title}</h2>
                      <p className="inbox-post-date">
                        Publisert:{' '}
                        {post.createdAt?.toDate
                          ? post.createdAt.toDate().toLocaleString()
                          : new Date(post.createdAt.seconds * 1000).toLocaleString()}
                      </p>
                      {isUnread && <span className="inbox-post-new-badge">Ny</span>}
                    </div>
                    {isExpanded && (
                      <div className="inbox-post-expanded">
                        <div className="inbox-post-content-box">
                          <div
                            className="inbox-post-content"
                            dangerouslySetInnerHTML={{ __html: postContent }}
                          ></div>
                        </div>
                        <p className="inbox-post-message">
                          Les alle innlegg i{' '}
                          <span
                            className="inbox-post-link"
                            onClick={handleReadFullPost}
                          >
                            Min Klubb
                          </span>
                          .
                        </p>
                      </div>
                    )}
                   
                  </div>
                  
                );
                
              })
              
            )}
             
             <p className="inbox-bottom-text">
              Du har ingen flere varsler enn dette. Nyt dagen!
            </p>
          </div>


        </section>
      </main>
    </div>
  );
};

export default Inbox;
