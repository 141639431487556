// src/components/MyClub.js

import React, { useEffect, useState, useRef } from 'react';
import './MyClub.css';
import Sidebar from './Sidebar';
import Spinner from './Spinner';
import { useUserContext } from '../context/UserContext';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import ReactMarkdown from 'react-markdown';
import {
  FaEnvelope,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaPlane,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';

const MyClub = () => {
  const { clubData } = useUserContext();
  const [currentUser, setCurrentUser] = useState(null);
  const [members, setMembers] = useState([]);
  const [planes, setPlanes] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedMemberId, setExpandedMemberId] = useState(null);
  const [expandedPlaneId, setExpandedPlaneId] = useState(null);
  const [planeBookings, setPlaneBookings] = useState({});
  const [now, setNow] = useState(new Date());
  const [postsToShow, setPostsToShow] = useState(1);
  const [activePost, setActivePost] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [commentDrafts, setCommentDrafts] = useState({});

  const commentInputRef = useRef(null);

  const db = getFirestore();
  const auth = getAuth();

  const MAX_WORDS = 50; // Maximum number of words to display in post preview

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = auth.currentUser;
      if (user && clubData) {
        // Find the member data for the current user
        const membersRef = collection(db, `clubs/${clubData.orgnr}/members`);
        const membersSnapshot = await getDocs(membersRef);
        const membersData = membersSnapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        }));
        const currentMember = membersData.find(
          (member) => member.uid === user.uid
        );
        setCurrentUser(currentMember);
      }
    };

    fetchCurrentUser();
  }, [auth, clubData, db]);

  useEffect(() => {
    const fetchClubData = async () => {
      if (!clubData) {
        setLoading(false);
        return;
      }

      try {
        // Fetch club members
        const membersRef = collection(db, `clubs/${clubData.orgnr}/members`);
        const membersSnapshot = await getDocs(membersRef);
        const membersData = membersSnapshot.docs.map((doc) => ({
          uid: doc.id,
          ...doc.data(),
        }));
        setMembers(membersData);

        // Fetch club posts with comments
        const postsRef = collection(db, `clubs/${clubData.orgnr}/posts`);
        const q = query(postsRef, orderBy('createdAt', 'desc'));
        const postsSnapshot = await getDocs(q);
        const postsData = await Promise.all(
          postsSnapshot.docs.map(async (doc) => {
            const postId = doc.id;
            const postData = doc.data();

            // Fetch comments for this post
            const commentsRef = collection(
              db,
              `clubs/${clubData.orgnr}/posts/${postId}/comments`
            );
            const commentsSnapshot = await getDocs(
              query(commentsRef, orderBy('createdAt', 'desc'))
            );
            const commentsData = commentsSnapshot.docs.map((commentDoc) => ({
              id: commentDoc.id,
              ...commentDoc.data(),
            }));

            return {
              id: postId,
              ...postData,
              comments: commentsData,
            };
          })
        );
        setPosts(postsData);

        // Fetch club planes
        const planesRef = collection(db, `clubs/${clubData.orgnr}/planes`);
        const planesSnapshot = await getDocs(planesRef);
        const planesData = planesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPlanes(planesData);

        // Fetch bookings for each plane
        const bookingsData = {};
        for (const plane of planesData) {
          const bookingsRef = collection(
            db,
            `clubs/${clubData.orgnr}/planes/${plane.id}/bookings`
          );
          const bookingsSnapshot = await getDocs(bookingsRef);
          const bookings = bookingsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          bookingsData[plane.id] = bookings;
        }
        setPlaneBookings(bookingsData);
      } catch (error) {
        console.error('Error fetching club data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClubData();

    // Update the current time every minute
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [clubData, db]);

  // Helper function to format timestamps
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    let date;
    if (timestamp instanceof Date) {
      date = timestamp;
    } else if (timestamp.toDate) {
      date = timestamp.toDate();
    } else {
      date = new Date(timestamp);
    }
    return date.toLocaleString('no-NO');
  };

  // Function to display role in Norwegian
  const displayRole = (role) => {
    if (role === 'member') {
      return 'Medlem';
    } else if (role === 'admin') {
      return 'Administrator';
    } else if (role === 'instructor') {
      return 'Instruktør';
    } else {
      return role;
    }
  };

  // Toggle expanded member card
  const toggleMemberDetails = (uid) => {
    setExpandedMemberId(expandedMemberId === uid ? null : uid);
  };

  // Toggle expanded plane card
  const togglePlaneDetails = (planeId) => {
    setExpandedPlaneId(expandedPlaneId === planeId ? null : planeId);
  };

  // Function to generate club description
  const generateClubDescription = () => {
    const { stiftelsesdato } = clubData;
    return `Stiftet ${format(new Date(stiftelsesdato), 'd. MMMM yyyy', {
      locale: nbLocale,
    })}`;
  };

  // Function to check plane availability
  const getPlaneAvailability = (planeId) => {
    const bookings = planeBookings[planeId] || [];
    const currentTime = now;

    let nextAvailableTime = null;

    for (const booking of bookings) {
      const start = new Date(booking.start);
      const end = new Date(booking.end);

      if (currentTime >= start && currentTime <= end) {
        if (!nextAvailableTime || end > nextAvailableTime) {
          nextAvailableTime = end;
        }
      } else if (currentTime < start) {
        if (!nextAvailableTime || start < nextAvailableTime) {
          nextAvailableTime = start;
        }
      }
    }

    if (nextAvailableTime && currentTime < nextAvailableTime) {
      if (
        bookings.some(
          (booking) =>
            currentTime >= new Date(booking.start) &&
            currentTime <= new Date(booking.end)
        )
      ) {
        return {
          available: false,
          until: nextAvailableTime,
        };
      } else {
        return {
          available: true,
          until: nextAvailableTime,
        };
      }
    } else {
      return {
        available: true,
        until: null,
      };
    }
  };

  // Handle "Show More Posts" button click
  const handleShowMorePosts = () => {
    setPostsToShow((prev) => prev + 5);
  };

  // Open Post Modal
  const openPostModal = (post, initialComment = '') => {
    setActivePost(post);
    setNewComment(initialComment);
  };

  // Close Post Modal
  const closePostModal = () => {
    setActivePost(null);
    setNewComment('');
  };

  // Handle Comment Draft Change
  const handleCommentDraftChange = (postId, text) => {
    setCommentDrafts((prevDrafts) => ({
      ...prevDrafts,
      [postId]: text,
    }));
  };

  // Handle Add Comment
  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;
    try {
      const commentData = {
        content: newComment,
        userId: currentUser.uid,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        createdAt: serverTimestamp(),
      };
      const commentsRef = collection(
        db,
        `clubs/${clubData.orgnr}/posts/${activePost.id}/comments`
      );
      const docRef = await addDoc(commentsRef, commentData);

      // Create a local copy of the comment with a Date object for createdAt
      const newCommentWithId = {
        id: docRef.id,
        ...commentData,
        createdAt: new Date(), // Use local time until Firestore updates
      };

      // Update local state
      setActivePost((prevActivePost) => ({
        ...prevActivePost,
        comments: [newCommentWithId, ...prevActivePost.comments],
      }));
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === activePost.id
            ? { ...post, comments: [newCommentWithId, ...post.comments] }
            : post
        )
      );
      setCommentDrafts((prevDrafts) => ({
        ...prevDrafts,
        [activePost.id]: '',
      }));
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  useEffect(() => {
    if (activePost && commentInputRef.current) {
      commentInputRef.current.focus();
    }
  }, [activePost]);

  // Function to truncate content to a maximum number of words
  const truncateContent = (content, maxWords) => {
    const words = content.split(' ');
    if (words.length <= maxWords) {
      return content;
    }
    return words.slice(0, maxWords).join(' ') + '...';
  };

  if (loading) {
    return (
      <div className="app-wrapper">
        <Sidebar />
        <Spinner />
      </div>
    );
  }

  if (!clubData) {
    return (
      <div className="app-wrapper">
        <Sidebar />
        <main className="myclub-main-content">
          <h2>Ingen klubbdata tilgjengelig</h2>
        </main>
      </div>
    );
  }

  return (
    <div className="app-wrapper">
      <Sidebar />
      <main className="myclub-main-content">
        {/* Main Content Grid */}
        <section className="myclub-content-grid">
          {/* Left Column */}
          <div className="myclub-left-column">
            {/* Club Header */}
            <div className="myclub-header-content">
              <h1 className="myclub-club-name">{clubData.navn}</h1>
              <p className="myclub-club-description">
                {clubData.description || generateClubDescription()}
              </p>
              <div className="myclub-club-location">
                <FaMapMarkerAlt className="myclub-location-icon" />
                <span>{clubData.poststed || 'Lokasjon ikke oppgitt'}</span>
              </div>
            </div>

            {/* Club News Section */}
            <section className="myclub-news-section">
              <h2 className="myclub-section-title">Klubbnyheter</h2>
              {posts.length === 0 ? (
                <p>Ingen nyheter tilgjengelig.</p>
              ) : (
                <>
                  {posts.slice(0, postsToShow).map((post) => (
                    <div key={post.id} className="myclub-news-post">
                      <h3 className="myclub-news-post-title">{post.title}</h3>
                      <p className="myclub-news-post-meta">
                        Publisert{' '}
                        {post.createdAt
                          ? formatTimestamp(post.createdAt)
                          : ''}
                      </p>
                      <div className="myclub-news-post-content">
                        <ReactMarkdown>
                          {truncateContent(post.content, MAX_WORDS)}
                        </ReactMarkdown>
                      </div>
                      {/* Show More Link */}
                      {post.content.split(' ').length > MAX_WORDS && (
                        <button
                          className="myclub-read-more-button"
                          onClick={() => openPostModal(post)}
                        >
                          Les mer
                        </button>
                      )}

                      {/* Comments Preview */}
                      <div className="myclub-comments-preview">
                        <p
                          className="myclub-comments-count"
                          onClick={() => openPostModal(post)}
                        >
                          {post.comments.length} kommentarer
                        </p>
                        {post.comments
                          .slice(0, 2)
                          .reverse()
                          .map((comment) => (
                            <div key={comment.id} className="myclub-comment">
                              <p className="myclub-comment-author">
                                {comment.firstName} {comment.lastName}:
                              </p>
                              <p className="myclub-comment-content">
                                {comment.content}
                              </p>
                            </div>
                          ))}

                        {/* Input to Add Comment */}
                        <input
                          type="text"
                          placeholder="Skriv en kommentar..."
                          value={commentDrafts[post.id] || ''}
                          onChange={(e) =>
                            handleCommentDraftChange(post.id, e.target.value)
                          }
                          onFocus={() =>
                            openPostModal(post, commentDrafts[post.id] || '')
                          }
                          className="myclub-add-comment-input"
                        />
                      </div>
                    </div>
                  ))}
                  {postsToShow < posts.length && (
                    <button
                      className="myclub-show-more-button"
                      onClick={handleShowMorePosts}
                    >
                      Vis flere innlegg
                    </button>
                  )}
                </>
              )}
            </section>
          </div>

          {/* Right Column */}
          <div className="myclub-right-column">
            {/* Members Section */}
            <section className="myclub-members-section">
              <h2 className="myclub-section-title">Medlemmer</h2>
              <div className="myclub-members-list">
                {members.map((member) => (
                  <div
                    key={member.uid}
                    className={`myclub-member-card ${
                      expandedMemberId === member.uid ? 'expanded' : ''
                    }`}
                  >
                    <div
                      className="myclub-member-header"
                      onClick={() => toggleMemberDetails(member.uid)}
                    >
                      <div className="myclub-member-name-role">
                        <h3>
                          {member.firstName} {member.lastName}
                        </h3>
                        <p>{displayRole(member.role)}</p>
                      </div>
                      <div className="myclub-member-toggle-icon">
                        {expandedMemberId === member.uid ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        )}
                      </div>
                    </div>
                    {expandedMemberId === member.uid && (
                      <div className="myclub-member-details">
                        <div className="myclub-member-contact">
                          <p>
                            <FaEnvelope /> {member.email}
                          </p>
                          {member.phone && (
                            <p>
                              <FaPhoneAlt /> {member.phone}
                            </p>
                          )}
                        </div>
                        <button
                          className="myclub-start-chat-button"
                          onClick={() =>
                            alert(
                              `Start chat med ${member.firstName} (UID: ${member.uid})`
                            )
                          }
                        >
                          Start chat
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </section>

            {/* Planes Section */}
            <section className="myclub-planes-section">
              <h2 className="myclub-section-title">Klubbfly</h2>
              <div className="myclub-planes-list">
                {planes.length === 0 ? (
                  <p>Ingen fly registrert.</p>
                ) : (
                  planes.map((plane) => {
                    const availability = getPlaneAvailability(plane.id);
                    return (
                      <div
                        key={plane.id}
                        className={`myclub-plane-card ${
                          expandedPlaneId === plane.id ? 'expanded' : ''
                        }`}
                      >
                        <div
                          className="myclub-plane-header"
                          onClick={() => togglePlaneDetails(plane.id)}
                        >
                          <div className="myclub-plane-info">
                            <h3>
                              <FaPlane /> {plane.callsign}
                            </h3>
                            <p>{plane.manufacturer + ' ' + plane.model}</p>
                            {availability.available ? (
                              <p className="available-text">
                                Tilgjengelig{' '}
                                {availability.until
                                  ? `til ${format(
                                      new Date(availability.until),
                                      'd. MMMM yyyy HH:mm',
                                      { locale: nbLocale }
                                    )}`
                                  : ''}
                              </p>
                            ) : (
                              <p className="unavailable-text">
                                Utilgjengelig frem til{' '}
                                {format(
                                  new Date(availability.until),
                                  'd. MMMM yyyy HH:mm',
                                  { locale: nbLocale }
                                )}
                              </p>
                            )}
                          </div>
                          <div className="myclub-plane-toggle-icon">
                            {expandedPlaneId === plane.id ? (
                              <FaChevronUp />
                            ) : (
                              <FaChevronDown />
                            )}
                          </div>
                        </div>
                        {expandedPlaneId === plane.id && (
                          <div className="myclub-plane-details">
                            <p>Farge: {plane.color}</p>
                            <p>Motor: {plane.engineType}</p>
                            <p>Årsmodell: {plane.year}</p>
                          </div>
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            </section>
          </div>
        </section>

        {/* Post Modal */}
        {activePost && (
          <div className="myclub-modal-overlay" onClick={closePostModal}>
            <div
              className="myclub-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className="myclub-modal-close-button"
                onClick={closePostModal}
              >
                &times;
              </button>
              {/* Post Content */}
              <div className="myclub-modal-post">
                <h3 className="myclub-news-post-title">{activePost.title}</h3>
                <p className="myclub-news-post-meta">
                  Publisert{' '}
                  {activePost.createdAt
                    ? formatTimestamp(activePost.createdAt)
                    : ''}
                </p>
                <div className="myclub-news-post-content">
                  <ReactMarkdown>{activePost.content}</ReactMarkdown>
                </div>
              </div>
              {/* Comments Section */}
              <div className="myclub-modal-comments">
                <h3>Kommentarer</h3>
                {activePost.comments.map((comment) => (
                  <div key={comment.id} className="myclub-modal-comment">
                    <p className="myclub-comment-author">
                      {comment.firstName} {comment.lastName} -{' '}
                      {formatTimestamp(comment.createdAt)}
                    </p>
                    <p className="myclub-comment-content">{comment.content}</p>
                  </div>
                ))}

                {/* Input to Add Comment */}
                <form onSubmit={handleAddComment}>
                  <input
                    type="text"
                    placeholder="Skriv en kommentar..."
                    value={newComment}
                    onChange={(e) => {
                      setNewComment(e.target.value);
                      handleCommentDraftChange(activePost.id, e.target.value);
                    }}
                    className="myclub-add-comment-input"
                    ref={commentInputRef}
                  />
                  <button type="submit" className="myclub-add-comment-button">
                    Legg til kommentar
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default MyClub;
