// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Spinner from './Spinner';

const ProtectedRoute = ({ children }) => {
  const auth = getAuth();
  const [isAuthenticated, setIsAuthenticated] = React.useState(null);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [auth]);

  if (isAuthenticated === null) {
    // You can render a loading spinner here while checking authentication
    return <Spinner />; // Use the spinner for the error state as well
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
