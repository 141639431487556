// src/components/Spinner.js

import React from 'react';
import './Spinner.css';  // Import the CSS for the spinner animation
import PlainLogo from '../assets/plain_logo.png';  // Import the logo
  

const Spinner = () => {
  return (
    <div className="spinner-container">
      <meta name="theme-color" content="#ffffff" />

      <img src={PlainLogo} alt="Loading..." className="spinner-logo" />
    </div>
  );
};

export default Spinner;
