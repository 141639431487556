import React, { createContext, useState, useContext, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [clubData, setClubData] = useState(null);
  const [planes, setPlanes] = useState([]); // Store planes for the club
  const [loading, setLoading] = useState(true);
  const [userClubs, setUserClubs] = useState([]);
  const [clubSelected, setClubSelected] = useState(false); // Flag to remember club selection
  const navigate = useNavigate();  // For navigation

  const fetchMemberData = async (clubId, email) => {
    const db = getFirestore();
    const membersRef = collection(db, `clubs/${clubId}/members`);
    const q = query(membersRef, where('email', '==', email));
    const memberSnapshot = await getDocs(q);
    if (!memberSnapshot.empty) {
      const memberData = memberSnapshot.docs[0].data();
      setUserData(memberData);
    }
  };

  const fetchPlanes = async (clubId) => {
    const db = getFirestore();
    const planesRef = collection(db, `clubs/${clubId}/planes`);
    const planesSnapshot = await getDocs(planesRef);
    const planesData = planesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setPlanes(planesData);
  };

  useEffect(() => {
    const fetchClubsForUser = async (user) => {
      if (clubSelected) return; // If the club is already selected, don't re-fetch and re-navigate

      try {
        const db = getFirestore();
        const clubCollectionRef = collection(db, 'clubs');
        const clubsSnapshot = await getDocs(clubCollectionRef);

        let foundClubs = [];

        for (const clubDoc of clubsSnapshot.docs) {
          const clubRef = clubDoc.ref;
          const membersRef = collection(clubRef, 'members');
          const q = query(membersRef, where('email', '==', user.email));
          const memberSnapshot = await getDocs(q);

          if (!memberSnapshot.empty) {
            foundClubs.push({
              clubId: clubDoc.id,
              ...clubDoc.data(),
            });
          }
        }

        if (foundClubs.length > 0) {
          setUserClubs(foundClubs);

          if (foundClubs.length === 1 && !clubSelected) {
            setClubData(foundClubs[0]);
            setClubSelected(true);
            await fetchMemberData(foundClubs[0].clubId, user.email);
            await fetchPlanes(foundClubs[0].clubId); // Fetch planes for the club
            navigate('/dashboard');
          } else if (!clubSelected) {
            navigate('/select-club');
          }
        } else {
          console.error('No matching clubs found for user');
        }
      } catch (error) {
        console.error('Error fetching clubs for user:', error);
      } finally {
        setLoading(false);
      }
    };

    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchClubsForUser(user);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [navigate, clubSelected]); // Dependency array with `clubSelected`

  const handleSelectClub = async (clubId) => {
    const selectedClub = userClubs.find((club) => club.clubId === clubId);
    if (selectedClub) {
      setClubData(selectedClub);
      setClubSelected(true);
      await fetchMemberData(clubId, selectedClub.admin.email);
      await fetchPlanes(clubId); // Fetch planes for the selected club
      navigate('/dashboard');
    }
  };

  return (
    <UserContext.Provider value={{ userData, clubData, planes, userClubs, loading, setUserData, setClubData, handleSelectClub, setClubSelected }}>
      {children}
    </UserContext.Provider>
  );
};
