import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; // Sidebar imported
import './Chat.css'; // Importing the CSS file
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { useUserContext } from '../context/UserContext';

const Chat = () => {
  const { clubData } = useUserContext();
  const [searchTerm, setSearchTerm] = useState(''); // Search term for searching members
  const [searchResults, setSearchResults] = useState([]); // Stores search results
  const [selectedChat, setSelectedChat] = useState(null); // Selected chat
  const [messages, setMessages] = useState([]); // Placeholder for messages

  const sampleChats = [
    { id: 1, name: 'Test1', lastMessage: 'Når kan vi fly?', time: '10:15' },
    { id: 2, name: 'Test2', lastMessage: 'Betalingskrav sendt.', time: '09:30' },
    { id: 3, name: 'Test3', lastMessage: 'Ta en runde imorgen?', time: '12:45' },
  ];

  const sampleMessages = [
    { sender: 'Test4', message: 'Hei! Når er du ledig?', time: '10:15' },
    { sender: 'You', message: 'Jeg er ledig i morgen!', time: '10:20' },
    { sender: 'Test4', message: 'Perfekt! Vi ses da.', time: '10:25' },
  ];

  const db = getFirestore();

  // Search function to query members from Firebase
  const handleSearch = async () => {
    if (!searchTerm || !clubData) return;
    const membersRef = collection(db, `clubs/${clubData.orgnr}/members`);
    const q = query(membersRef, where('name', '>=', searchTerm)); // Firebase search for members
    const querySnapshot = await getDocs(q);
    const members = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSearchResults(members);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  // Select a chat to display
  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    setMessages(sampleMessages); // Load sample messages for the selected chat
  };

  return (
    <div className="chat-wrapper">
      <meta name="theme-color" content="#f0f2f5" />
      <Sidebar />
      <main className="chat-main">
        <div className="chat-list-container">
          <input
            type="text"
            placeholder="Søk etter medlemmer"
            className="chat-search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="chat-search-results">
            {searchResults.map((member) => (
              <div key={member.id} className="chat-search-result" onClick={() => handleChatSelect(member)}>
                {member.name}
              </div>
            ))}
          </div>
          <div className="chat-list">
            {sampleChats.map((chat) => (
              <div
                key={chat.id}
                className={`chat-card ${selectedChat?.id === chat.id ? 'selected-chat' : ''}`}
                onClick={() => handleChatSelect(chat)}
              >
                <div className="chat-card-name">{chat.name}</div>
                <div className="chat-card-last-message">{chat.lastMessage}</div>
                <div className="chat-card-time">{chat.time}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="chat-window">
          {selectedChat ? (
            <div>
              <div className="chat-header">{selectedChat.name}</div>
              <div className="chat-messages">
                {messages.map((msg, index) => (
                  <div key={index} className={`chat-message ${msg.sender === 'You' ? 'my-message' : ''}`}>
                    <div className="chat-message-sender">{msg.sender}</div>
                    <div className="chat-message-text">{msg.message}</div>
                    <div className="chat-message-time">{msg.time}</div>
                  </div>
                ))}
              </div>
              <br />
              <div className="chat-input-container">
                <input type="text" placeholder="Skriv en melding..." className="chat-input" />
                <button className="chat-send-button">Send</button>
              </div>
            </div>
          ) : (
            <div className="chat-placeholder">Velg en chat for å begynne å snakke!</div>
          )}
        </div>
      </main>
    </div>
  );
};

export default Chat;
