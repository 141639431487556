// PlaneCalendar.js

import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import "./PlaneCalendar.css";
import { FaPlane } from "react-icons/fa";
import { useUserContext } from "../context/UserContext";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  doc,
  getDoc,
  deleteDoc, // Import deleteDoc
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import nb from "date-fns/locale/nb";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as d3 from "d3";

const locales = {
  nb: nb,
};

const localizer = dateFnsLocalizer({
  format,
  parse: (str) => parseISO(str),
  startOfWeek,
  getDay,
  locales,
});

const PlaneCalendar = () => {
  const { clubData, userData } = useUserContext();
  const [planes, setPlanes] = useState([]);
  const [selectedPlane, setSelectedPlane] = useState(null);
  const [events, setEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [students, setStudents] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [bookingType, setBookingType] = useState("Privat");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedInstructor, setSelectedInstructor] = useState("");
  const [expectedFlightDuration, setExpectedFlightDuration] = useState("");
  const [seatsRemaining, setSeatsRemaining] = useState("");
  const [notes, setNotes] = useState("");
  const [showScheduler, setShowScheduler] = useState(false);
  const [planeAvailabilities, setPlaneAvailabilities] = useState({});
  const [weightBalanceData, setWeightBalanceData] = useState(null);
  const [openWbModal, setOpenWbModal] = useState(false);
  const [wbInputs, setWbInputs] = useState({
    pilotWeight: "",
    frontPassengerWeight: "",
    rearPassengerWeight: "",
    cargoWeight: "",
    baggageWeight: "",
    fuelQuantity: "",
  });
  const [wbErrors, setWbErrors] = useState({});
  const wbGraphRef = useRef();

  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const fetchPlanes = async () => {
      const planesRef = collection(db, `clubs/${clubData.orgnr}/planes`);
      const planesSnapshot = await getDocs(planesRef);
      const planesData = planesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPlanes(planesData);

      const availabilities = {};
      for (const plane of planesData) {
        availabilities[plane.id] = await checkPlaneAvailability(plane.id);
      }
      setPlaneAvailabilities(availabilities);
    };

    if (clubData) {
      fetchPlanes();
    }
  }, [clubData, db]);

  useEffect(() => {
    const fetchMembers = async () => {
      const membersRef = collection(db, `clubs/${clubData.orgnr}/members`);
      const membersSnapshot = await getDocs(membersRef);
      const membersData = membersSnapshot.docs.map((doc) => ({
        uid: doc.id,
        ...doc.data(),
      }));

      setStudents(membersData);
      setInstructors(membersData.filter((member) => member.role === "instructor"));
    };

    if (clubData) {
      fetchMembers();
    }
  }, [clubData, db]);

  useEffect(() => {
    const fetchBookings = async () => {
      if (selectedPlane) {
        const bookingsRef = collection(
          db,
          `clubs/${clubData.orgnr}/planes/${selectedPlane.id}/bookings`
        );
        const bookingsSnapshot = await getDocs(bookingsRef);
        const bookingsData = bookingsSnapshot.docs
          .map((bookingDoc) => {
            const booking = bookingDoc.data();
            if (!booking.start || !booking.end) {
              return null;
            }
            const startDate = parseISO(booking.start);
            const endDate = parseISO(booking.end);

            if (isNaN(startDate) || isNaN(endDate)) {
              return null;
            }

            return {
              docId: bookingDoc.id, // Add docId for deletion
              event_id: booking.event_id,
              title: `${booking.firstName} ${booking.lastName}`,
              start: startDate,
              end: endDate,
              description: booking.notes || "No description",
              bookingData: booking,
            };
          })
          .filter((event) => event !== null);
        setEvents(bookingsData);
      }
    };

    if (selectedPlane) {
      fetchBookings();
    }
  }, [selectedPlane, clubData, db]);

  const checkPlaneAvailability = async (planeId) => {
    const bookingsRef = collection(
      db,
      `clubs/${clubData.orgnr}/planes/${planeId}/bookings`
    );
    const bookingsSnapshot = await getDocs(bookingsRef);
    const now = new Date();

    for (const bookingDoc of bookingsSnapshot.docs) {
      const booking = bookingDoc.data();
      if (!booking.start || !booking.end) {
        continue;
      }
      const startDate = new Date(booking.start);
      const endDate = new Date(booking.end);

      if (now >= startDate && now <= endDate) {
        return false;
      }
    }
    return true;
  };

  const handleBooking = async () => {
    const currentUser = auth.currentUser;

    if (!currentUser || !userData) {
      console.error("User is not authenticated or user data is missing");
      return;
    }

    if (!startTime || !endTime) {
      alert("Vennligst velg starttid og slutttid for bookingen.");
      return;
    }

    if (endTime <= startTime) {
      alert("Slutttid må være etter starttid.");
      return;
    }

    const eventId = uuidv4();

    try {
      const bookingRef = collection(
        db,
        `clubs/${clubData.orgnr}/planes/${selectedPlane.id}/bookings`
      );

      const newBooking = {
        event_id: eventId,
        firstName: userData.firstName,
        lastName: userData.lastName,
        bookingType,
        userBooking: currentUser.uid, // Add the UID of the user booking
        student: selectedStudent,
        instructor: selectedInstructor,
        start: startTime.toISOString(),
        end: endTime.toISOString(),
        expectedFlightDuration,
        seatsRemaining,
        notes,
        weightBalanceData, // Save the weight and balance data
      };

      const docRef = await addDoc(bookingRef, newBooking);

      setOpenModal(false);

      setEvents((prevEvents) => [
        ...prevEvents,
        {
          docId: docRef.id, // Save the document ID for deletion
          event_id: eventId,
          title: `${userData.firstName} ${userData.lastName}`,
          start: startTime,
          end: endTime,
          description: notes,
          bookingData: newBooking,
        },
      ]);

      // Reset booking fields
      setStartTime(null);
      setEndTime(null);
      setBookingType("Privat");
      setSelectedStudent("");
      setSelectedInstructor("");
      setExpectedFlightDuration("");
      setSeatsRemaining("");
      setNotes("");
      setWeightBalanceData(null);
      setWbInputs({
        pilotWeight: "",
        frontPassengerWeight: "",
        rearPassengerWeight: "",
        cargoWeight: "",
        baggageWeight: "",
        fuelQuantity: "",
      });
    } catch (error) {
      console.error("Error booking the plane:", error);
    }
  };

  const findStudentNameById = (studentId) => {
    const student = students.find((s) => s.uid === studentId);
    return student ? `${student.firstName} ${student.lastName}` : "Ingen elev valgt";
  };

  const findInstructorNameById = (instructorId) => {
    const instructor = instructors.find((i) => i.uid === instructorId);
    return instructor
      ? `${instructor.firstName} ${instructor.lastName}`
      : "Ingen lærer valgt";
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalMode("view");
    setOpenModal(true);
  };

  const handleCreateBooking = ({ start, end }) => {
    setStartTime(start);
    setEndTime(end);
    setModalMode("create");
    setOpenModal(true);
  };

  const handleOpenCreateBookingModal = () => {
    setStartTime(null);
    setEndTime(null);
    setModalMode("create");
    setOpenModal(true);
  };

  // Delete Booking Function
  const handleDeleteBooking = async () => {
    if (!selectedEvent || !selectedEvent.docId) {
      console.error("No booking selected or missing document ID");
      return;
    }

    const currentUser = auth.currentUser;
    if (currentUser.uid !== selectedEvent.bookingData.userBooking) {
      alert("Du har ikke tillatelse til å slette denne bookingen.");
      return;
    }

    try {
      const bookingDocRef = doc(
        db,
        `clubs/${clubData.orgnr}/planes/${selectedPlane.id}/bookings`,
        selectedEvent.docId
      );
      await deleteDoc(bookingDocRef);

      // Remove the event from the state
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.docId !== selectedEvent.docId)
      );

      setOpenModal(false);
      setSelectedEvent(null);
    } catch (error) {
      console.error("Error deleting booking:", error);
    }
  };

  // Weight and Balance Functions

  const handleOpenWbModal = () => {
    setOpenWbModal(true);
  };

  const handleCloseWbModal = () => {
    setOpenWbModal(false);
    setWbErrors({});
  };

  const handleWbInputChange = (e) => {
    const { name, value } = e.target;
    setWbInputs({ ...wbInputs, [name]: value });
  };

  const validateWbInputs = () => {
    const errors = {};
    if (!wbInputs.pilotWeight) errors.pilotWeight = "Pilots vekt er påkrevd";
    if (!wbInputs.fuelQuantity) errors.fuelQuantity = "Drivstoffmengde er påkrevd";
    // Add more validations as needed
    setWbErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCalculateWb = async () => {
    if (!validateWbInputs()) {
      return;
    }

    // Fetch aircraft-specific data for selected plane
    let aircraftData = null;

    if (selectedPlane) {
      try {
        const planeDocRef = doc(
          db,
          `clubs/${clubData.orgnr}/planes/${selectedPlane.id}`
        );
        const planeDocSnap = await getDoc(planeDocRef);
        if (planeDocSnap.exists()) {
          aircraftData = planeDocSnap.data();
        }
      } catch (error) {
        console.error("Error fetching aircraft data:", error);
        return;
      }
    }

    if (!aircraftData) {
      alert("Kunne ikke hente flydata.");
      return;
    }

    // Use aircraft data from Firebase or hardcoded LN-TWS data
    // For demonstration, we'll use the LN-TWS data provided
    // You can store this data in Firebase under the plane's document for dynamic usage

    // Constants from LN-TWS data
    const MTOM_lbs = 2400; // Max Takeoff Mass in lbs
    const MZFW_lbs = 2400; // Max Zero Fuel Weight in lbs

    const fuelCapacity_USG = 51; // Usable fuel in USG
    const fuelWeightPerUSG = 6; // lbs per USG
    const fullFuelWeight_lbs = fuelCapacity_USG * fuelWeightPerUSG; // 306 lbs

    const emptyWeight_lbs = 1552; // Basic Empty Weight in lbs
    const emptyArm_in = 84.12; // From provided data
    const emptyMoment_inlbs = emptyWeight_lbs * emptyArm_in; // 130554.24 in-lbs

    const maxPayloadWithFullFuel_lbs = 542; // Max payload with full fuel

    // Arms (from provided data)
    const arms = {
      pilot: 90.6, // inches
      frontPassenger: 90.6, // same as pilot
      rearPassenger: 126, // inches
      cargo: 116.4, // inches
      baggage: 151, // inches
      fuel: 94.8, // inches
      oil: 32, // inches (if applicable)
    };

    // Weight limits
    const baggageWeightLimit_lbs = 120; // Aft Baggage Compartment
    const cargoWeightLimit_lbs = 340; // Cargo Area

    // Weights from user inputs
    const pilotWeight = parseFloat(wbInputs.pilotWeight) || 0;
    const frontPassengerWeight = parseFloat(wbInputs.frontPassengerWeight) || 0;
    const rearPassengerWeight = parseFloat(wbInputs.rearPassengerWeight) || 0;
    const cargoWeight = parseFloat(wbInputs.cargoWeight) || 0;
    const baggageWeight = parseFloat(wbInputs.baggageWeight) || 0;
    const fuelQuantity = parseFloat(wbInputs.fuelQuantity) || 0;
    const fuelWeight = fuelQuantity * fuelWeightPerUSG;

    // Validate weight limits
    if (baggageWeight > baggageWeightLimit_lbs) {
      alert(
        `Bagasjevekt i bakre bagasjerom overstiger grensen på ${baggageWeightLimit_lbs} lbs`
      );
      return;
    }

    if (cargoWeight > cargoWeightLimit_lbs) {
      alert(
        `Vekt i cargo området overstiger grensen på ${cargoWeightLimit_lbs} lbs`
      );
      return;
    }

    // Moments
    const pilotMoment = pilotWeight * arms.pilot;
    const frontPassengerMoment = frontPassengerWeight * arms.frontPassenger;
    const rearPassengerMoment = rearPassengerWeight * arms.rearPassenger;
    const cargoMoment = cargoWeight * arms.cargo;
    const baggageMoment = baggageWeight * arms.baggage;
    const fuelMoment = fuelWeight * arms.fuel;
    const oilWeight_lbs = 15; // Assuming oil weight
    const oilMoment = oilWeight_lbs * arms.oil;

    // Total Weight and Moment
    const totalWeight =
      emptyWeight_lbs +
      pilotWeight +
      frontPassengerWeight +
      rearPassengerWeight +
      cargoWeight +
      baggageWeight +
      fuelWeight +
      oilWeight_lbs;

    const totalMoment =
      emptyMoment_inlbs +
      pilotMoment +
      frontPassengerMoment +
      rearPassengerMoment +
      cargoMoment +
      baggageMoment +
      fuelMoment +
      oilMoment;

    // Center of Gravity (CG)
    const cg = totalMoment / totalWeight;

    // Save the data
    const wbData = {
      pilotWeight,
      frontPassengerWeight,
      rearPassengerWeight,
      cargoWeight,
      baggageWeight,
      fuelQuantity,
      fuelWeight,
      totalWeight,
      totalMoment,
      cg,
      MTOM_lbs,
    };

    setWeightBalanceData(wbData);

    // Close the WB Modal
    setOpenWbModal(false);
  };

  // Use useEffect to plot the graph after weightBalanceData is updated
  useEffect(() => {
    if (weightBalanceData && modalMode === "create" && openModal) {
      plotWbGraph(weightBalanceData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weightBalanceData, modalMode, openModal]);

  const plotWbGraph = (wbData) => {
    // Clear previous graph
    d3.select(wbGraphRef.current).selectAll("*").remove();

    // Dimensions
    const margin = { top: 20, right: 20, bottom: 50, left: 50 };
    const width = 400 - margin.left - margin.right;
    const height = 350 - margin.top - margin.bottom;

    const svg = d3
      .select(wbGraphRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .style("background-color", "#fff")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define scales
    const x = d3.scaleLinear().range([0, width]).domain([80, 130]); // Adjust domain based on aircraft data
    const y = d3.scaleLinear().range([height, 0]).domain([1400, 2500]); // Adjust domain based on aircraft data

    // Define axes
    const xAxis = d3.axisBottom(x);
    const yAxis = d3.axisLeft(y);

    // Draw axes
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(xAxis)
      .append("text")
      .attr("x", width / 2)
      .attr("y", 40)
      .attr("fill", "#000")
      .style("text-anchor", "middle")
      .text("Lastarm (Tommer)");

    svg
      .append("g")
      .call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -40)
      .attr("fill", "#000")
      .style("text-anchor", "middle")
      .text("Total Vekt (lbs)");

    // Define the aircraft envelope based on LN-TWS data
    const envelopePoints = [
      { x: 84.12, y: 1552 }, // Empty weight
      { x: 90, y: 2400 }, // Forward limit at MTOM
      { x: 120, y: 2400 }, // Aft limit at MTOM
      { x: 126, y: 1500 }, // Aft limit at lower weight
      { x: 84.12, y: 1500 }, // Close the envelope
    ];

    // Draw envelope
    svg
      .append("path")
      .datum(envelopePoints)
      .attr("fill", "rgba(75,192,192,0.2)")
      .attr("stroke", "rgba(75,192,192,1)")
      .attr(
        "d",
        d3
          .line()
          .x((d) => x(d.x))
          .y((d) => y(d.y))
      );

    // Plot user's point
    svg
      .append("circle")
      .attr("cx", x(wbData.cg))
      .attr("cy", y(wbData.totalWeight))
      .attr("r", 5)
      .attr("fill", "red");
  };

  return (
    <div className="app-wrapper">
      <Sidebar />

      <main className="main-content">
        <section className="welcome-section">
          <h1>Book et fly</h1>
          <p>Velg et tilgjengelig fly og planlegg din neste flytur.</p>
        </section>

        {/* Show Plane Selection Cards */}
        {!showScheduler && (
          <div className="plane-card-container">
            {planes.length === 0 ? (
              <p className="no-planes-message">
                Ingen fly tilgjengelig i klubben. Ta kontakt med klubbadministratoren.
              </p>
            ) : (
              planes.map((plane) => (
                <div
                  key={plane.id}
                  className="plane-card"
                  onClick={() => {
                    setSelectedPlane(plane);
                    setShowScheduler(true);
                  }}
                >
                  <div className="plane-card-header">
                    <FaPlane className="plane-icon" />
                    <h3>{plane.callsign}</h3>
                  </div>
                  <p className="plane-model">
                    {plane.manufacturer + " " + plane.model}
                  </p>
                  <p className="plane-color">Farge: {plane.color}</p>
                  {/* Availability Info */}
                  <p
                    className={`plane-availability ${
                      planeAvailabilities[plane.id] ? "available" : "unavailable"
                    }`}
                  >
                    {planeAvailabilities[plane.id] ? "Tilgjengelig" : "Utilgjengelig"}
                  </p>
                  <button className="select-button">Velg</button>
                </div>
              ))
            )}
          </div>
        )}

        {/* Scheduler for selected plane */}
        {selectedPlane && showScheduler && (
          <div>
            <button
              className="back-button"
              onClick={() => {
                setShowScheduler(false);
                setSelectedPlane(null);
              }}
            >
              Tilbake
            </button>
            <h2 className="calendar-heading">
              {selectedPlane.callsign} Booking Kalender
            </h2>
            <div className="scheduler-container">
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                selectable
                onSelectSlot={handleCreateBooking}
                onSelectEvent={handleEventClick}
                defaultView="week"
                messages={{
                  month: "Måned",
                  week: "Uke",
                  day: "Dag",
                  today: "I dag",
                  previous: "Forrige",
                  next: "Neste",
                }}
              />
            </div>
            {/* Add the "Create a New Booking" button */}
            <div className="create-booking-button-container">
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenCreateBookingModal}
                style={{ marginTop: 20 }}
              >
                Opprett ny booking
              </Button>
            </div>
          </div>
        )}

        {/* Booking Modal */}
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box className="booking-modal">
            {modalMode === "create" ? (
              <>
                <h2>Opprett Flyvning</h2>
                <div className="form-group">
                  <label>Starttid</label>
                  <DatePicker
                    selected={startTime}
                    onChange={(date) => setStartTime(date)}
                    showTimeSelect
                    dateFormat="dd.MM.yyyy HH:mm"
                    locale="nb"
                    placeholderText="Velg starttid"
                    isClearable
                    timeFormat="HH:mm"
                    timeIntervals={15}
                  />
                </div>

                <div className="form-group">
                  <label>Slutttid</label>
                  <DatePicker
                    selected={endTime}
                    onChange={(date) => setEndTime(date)}
                    showTimeSelect
                    dateFormat="dd.MM.yyyy HH:mm"
                    locale="nb"
                    placeholderText="Velg slutttid"
                    isClearable
                    timeFormat="HH:mm"
                    timeIntervals={15}
                  />
                </div>

                <div className="form-group">
                  <label>Type av booking</label>
                  <select
                    value={bookingType}
                    onChange={(e) => {
                      setBookingType(e.target.value);
                      setSelectedStudent("");
                      setSelectedInstructor("");
                    }}
                  >
                    <option value="Privat">Privat</option>
                    <option value="Skoling med elev">Skoling med elev</option>
                    <option value="Skoling med lærer">Skoling med lærer</option>
                  </select>
                </div>

                {bookingType === "Skoling med elev" && (
                  <div className="form-group">
                    <label>Velg elev</label>
                    <select
                      value={selectedStudent}
                      onChange={(e) => setSelectedStudent(e.target.value)}
                    >
                      <option value="">Velg elev</option>
                      {students.map((student) => (
                        <option key={student.uid} value={student.uid}>
                          {student.firstName} {student.lastName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {bookingType === "Skoling med lærer" && (
                  <div className="form-group">
                    <label>Velg lærer</label>
                    <select
                      value={selectedInstructor}
                      onChange={(e) => setSelectedInstructor(e.target.value)}
                    >
                      <option value="">Velg lærer</option>
                      {instructors.map((instructor) => (
                        <option key={instructor.uid} value={instructor.uid}>
                          {instructor.firstName} {instructor.lastName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className="form-group">
                  <label>Forventet flytid</label>
                  <input
                    type="number"
                    value={expectedFlightDuration}
                    onChange={(e) => setExpectedFlightDuration(e.target.value)}
                    placeholder="F.eks. 1 time"
                    min="0"
                  />
                </div>

                <div className="form-group">
                  <label>Passasjerer ombord</label>
                  <input
                    type="number"
                    value={seatsRemaining}
                    onChange={(e) => setSeatsRemaining(e.target.value)}
                    placeholder="Antall passasjerer inkludert pilot"
                    min="0"
                  />
                </div>

                <div className="form-group">
                  <label>Notater</label>
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Eventuelle notater"
                  ></textarea>
                </div>

                {/* Weight and Balance Button */}
                <div className="button-group">
                  <Button
                    onClick={handleOpenWbModal}
                    variant="outlined"
                    color="primary"
                    disabled
                  >
                    Vekt & Balanse Kalkulator (kommer)
                  </Button>
                </div>

                {/* Display the Weight and Balance Graph within the booking modal */}
                {weightBalanceData && (
                  <div className="wb-graph-container" ref={wbGraphRef}>
                    {/* Graph will be rendered here by D3 */}
                  </div>
                )}

                <div className="button-group">
                  <Button onClick={handleBooking} variant="contained" color="primary">
                    Send
                  </Button>
                  <Button
                    onClick={() => setOpenModal(false)}
                    variant="outlined"
                    color="secondary"
                  >
                    Avbryt
                  </Button>
                </div>
              </>
            ) : (
              <div>
                {/* View Mode: Display booking details */}
                <h2>Booking Detaljer</h2>
                <p>Type av booking: {selectedEvent?.bookingData?.bookingType}</p>
                <p>
                  Starttid:{" "}
                  {selectedEvent?.start
                    ? format(selectedEvent.start, "dd.MM.yyyy HH:mm", { locale: nb })
                    : "Ikke oppgitt"}
                </p>
                <p>
                  Slutttid:{" "}
                  {selectedEvent?.end
                    ? format(selectedEvent.end, "dd.MM.yyyy HH:mm", { locale: nb })
                    : "Ikke oppgitt"}
                </p>
                <p>
                  Navn: {selectedEvent?.bookingData?.firstName}{" "}
                  {selectedEvent?.bookingData?.lastName}
                </p>

                {/* Show full name of the student based on uid */}
                {selectedEvent?.bookingData?.student && (
                  <p>
                    Elev: {findStudentNameById(selectedEvent.bookingData.student)}
                  </p>
                )}

                {selectedEvent?.bookingData?.instructor && (
                  <p>
                    Lærer:{" "}
                    {findInstructorNameById(selectedEvent.bookingData.instructor)}
                  </p>
                )}

                <p>
                  Forventet flytid:{" "}
                  {selectedEvent?.bookingData?.expectedFlightDuration ||
                    "Ikke oppgitt"}
                </p>
                <p>
                  Antall passasjerer:{" "}
                  {selectedEvent?.bookingData?.seatsRemaining || "Ikke oppgitt"}
                </p>
                <p>
                  Notater: {selectedEvent?.bookingData?.notes || "Ingen notater"}
                </p>

                {/* Display Weight and Balance Data */}
                {selectedEvent?.bookingData?.weightBalanceData && (
                  <div className="wb-details">
                    <h3>Vekt & Balanse Informasjon</h3>
                    <p>
                      Total Vekt:{" "}
                      {selectedEvent.bookingData.weightBalanceData.totalWeight.toFixed(2)}{" "}
                      lbs
                    </p>
                    <p>
                      Lastarm:{" "}
                      {selectedEvent.bookingData.weightBalanceData.cg.toFixed(2)} tommer
                    </p>
                    {/* You can add more details as needed */}
                  </div>
                )}

                <div className="button-group">
                  {auth.currentUser.uid === selectedEvent?.bookingData?.userBooking && (
                    <Button
                      onClick={handleDeleteBooking}
                      variant="contained"
                      color="secondary"
                    >
                      Slett Booking
                    </Button>
                  )}
                  <Button
                    onClick={() => setOpenModal(false)}
                    variant="outlined"
                    color="secondary"
                  >
                    Lukk
                  </Button>
                </div>
              </div>
            )}
          </Box>
        </Modal>

        {/* Weight and Balance Modal */}
        <Modal open={openWbModal} onClose={handleCloseWbModal}>
          <Box className="wb-modal">
            <h2>Vekt & Balanse Kalkulator</h2>
            <div className="form-group">
              <label>Pilots Vekt (lbs)</label>
              <input
                type="number"
                name="pilotWeight"
                value={wbInputs.pilotWeight}
                onChange={handleWbInputChange}
                className={wbErrors.pilotWeight ? "error" : ""}
              />
              {wbErrors.pilotWeight && (
                <span className="error-message">{wbErrors.pilotWeight}</span>
              )}
            </div>
            <div className="form-group">
              <label>Front Passasjer Vekt (lbs)</label>
              <input
                type="number"
                name="frontPassengerWeight"
                value={wbInputs.frontPassengerWeight}
                onChange={handleWbInputChange}
              />
            </div>
            <div className="form-group">
              <label>Bakre Passasjer Vekt (lbs)</label>
              <input
                type="number"
                name="rearPassengerWeight"
                value={wbInputs.rearPassengerWeight}
                onChange={handleWbInputChange}
              />
            </div>
            <div className="form-group">
              <label>Cargo Vekt (lbs)</label>
              <input
                type="number"
                name="cargoWeight"
                value={wbInputs.cargoWeight}
                onChange={handleWbInputChange}
              />
            </div>
            <div className="form-group">
              <label>Bagasjevekt (lbs)</label>
              <input
                type="number"
                name="baggageWeight"
                value={wbInputs.baggageWeight}
                onChange={handleWbInputChange}
              />
            </div>
            <div className="form-group">
              <label>Drivstoffmengde (USG)</label>
              <input
                type="number"
                name="fuelQuantity"
                value={wbInputs.fuelQuantity}
                onChange={handleWbInputChange}
                className={wbErrors.fuelQuantity ? "error" : ""}
              />
              {wbErrors.fuelQuantity && (
                <span className="error-message">{wbErrors.fuelQuantity}</span>
              )}
            </div>
            <div className="button-group">
              <Button
                onClick={handleCalculateWb}
                variant="contained"
                color="primary"
              >
                Beregn
              </Button>
              <Button
                onClick={handleCloseWbModal}
                variant="outlined"
                color="secondary"
              >
                Avbryt
              </Button>
            </div>
          </Box>
        </Modal>
      </main>
    </div>
  );
};

export default PlaneCalendar;
