import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, addDoc, deleteDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import './InviteSignup.css'; // Import the CSS file for styling
import logo from '../assets/logo_main.png';   // Replace with the path to your AirTrakr logo
import Spinner from './Spinner';

const InviteSignup = () => {
  const { inviteId } = useParams();
  const [inviteData, setInviteData] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const fetchInvite = async () => {
      const inviteRef = doc(db, `invites/${inviteId}`);
      const inviteSnap = await getDoc(inviteRef);

      if (inviteSnap.exists()) {
        const invite = inviteSnap.data();
        setInviteData(invite);
        setEmail(invite.email); // Prefill email
      } else {
        alert('Invalid invite.');
      }
      setLoading(false);
    };

    fetchInvite();
  }, [inviteId, db]);

  const handleSignup = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Add user to the club members collection
      await addDoc(collection(db, `clubs/${inviteData.clubId}/members`), {
        firstName: inviteData.firstName,  // Send first name from invite data
        lastName: inviteData.lastName,    // Send last name from invite data
        email,
        role: 'member',
        uid: user.uid,
      });

      // Delete the invite after successful signup
      await deleteDoc(doc(db, `invites/${inviteId}`));

      navigate('/login');
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="invite-signup-container">
      <div className="invite-signup-card">
        <div className="invite-signup-logo">
          <img src={logo} alt="AirTrakr Logo" />
        </div>
        <h1>Fullfør registrering</h1>
        <p>Velkommen, {inviteData.firstName} {inviteData.lastName}!</p>
        <form className="invite-signup-form">
          <div className="form-group">
            <label>Fornavn</label>
            <input
              type="text"
              value={inviteData.firstName}
              readOnly  // Prefill and make it read-only
            />
          </div>
          <div className="form-group">
            <label>Etternavn</label>
            <input
              type="text"
              value={inviteData.lastName}
              readOnly  // Prefill and make it read-only
            />
          </div>
          <div className="form-group">
            <label>E-post</label>
            <input
              type="email"
              value={email}
              readOnly  // Prefill and make it read-only
            />
          </div>
          <div className="form-group">
            <label>Passord</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="button" className="invite-signup-button" onClick={handleSignup}>
            Fullfør registrering
          </button>
        </form>
      </div>
    </div>
  );
};

export default InviteSignup;
