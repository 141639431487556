import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from './components/Signup';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Logbook from './components/Logbook';
import MyClub from './components/MyClub';
import AdminDashboard from './pages/AdminDashboard';
import MemberDashboard from './pages/MemberDashboard';
import PlaneCalendar from './components/PlaneCalendar';
import SelectClub from './components/SelectClub'; // Import SelectClub page
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute
import AdminControls from './components/AdminControls';
import Chat from './components/Chat';
import InviteSignup from './components/InviteSignup';
import Profile from './components/Profile';
import Inbox from './components/Inbox';

// Define your protected routes
const AppRouter = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />
      <Route path="/invites/:inviteId" element={<InviteSignup />} />

      {/* Protected Routes */}
      <Route 
        path="/select-club" 
        element={
          <ProtectedRoute>
            <SelectClub />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/dashboard" 
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/logbook" 
        element={
          <ProtectedRoute>
            <Logbook />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/myclub" 
        element={
          <ProtectedRoute>
            <MyClub />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/admin-dashboard" 
        element={
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        } 
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route 
        path="/member-dashboard" 
        element={
          <ProtectedRoute>
            <MemberDashboard />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/booking" 
        element={
          <ProtectedRoute>
            <PlaneCalendar />
          </ProtectedRoute>
        } 
      />
      <Route 
  path="/admin-controls" 
  element={
    <ProtectedRoute>
      <AdminControls />
    </ProtectedRoute>
  } 
/>
<Route
  path="/chat"
  element={
    <ProtectedRoute>
      <Chat />
    </ProtectedRoute>
  }
/>
<Route
  path="/inbox"
  element={
    <ProtectedRoute>
      <Inbox />
    </ProtectedRoute>
  }
/>

    </Routes>
  );
};

export default AppRouter;
